import React from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { IBulkPayoutsProps, TransactionType } from '+types';
import { filteredOutObjectProperty, getStoreBulkCurrency, queriesParams } from '+utils';

import BulkPayoutHelpers from './BulkPayoutHelpers';
import BulkTransactionList from './components/BulkTransactionList';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const BulkPayouts: React.FC<IBulkPayoutsProps> = () => {
  const feedbackInit = useFeedbackHandler();
  const currencies = getStoreBulkCurrency();
  const searchQuery = useSearchQuery();
  const activeCurrencyTab = searchQuery.value.tab || 'NGN';
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const status = searchQuery.value.status || [];
  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status
    ])
  };

  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(
    ['BULK_PAYOUTS', searchQuery.value, activeCurrencyTab],
    () => api.getBulkPayouts(page, limit, sortingParams, activeCurrencyTab),
    {
      keepPreviousData: true,
      onError: () => {
        let message = 'There has been an error getting bulk-payouts.';
        if (searchQuery.value?.sorterType)
          message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          },
          componentlevel: false
        });
      }
    }
  );

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const { transactions } = BulkPayoutHelpers;
  const tableDataKeys = Object.keys(transactions.fields({} as TransactionType).data);

  return (
    <div className="content-i">
      <div className="content-box">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex mb-4">
            <ul className="nav nav-tabs">
              {currencies?.map((currency: string) => (
                <li className="nav-item" key={currency}>
                  <button
                    type="button"
                    className={`nav-link ${activeCurrencyTab === currency ? 'active' : ''}`}
                    onClick={() => searchQuery.setQuery({ tab: currency })}
                  >
                    {currency}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <div className="row">
          <div className="col-sm-12">
            <Table
              data={data}
              loading={isFetching}
              className={transactions.className || ''}
              tableWrapperClassName="table-responsive"
              renderFields
              hasPagination
              tableHeadings={tableDataKeys}
              annotation={transactions.annotations}
              rowKey={transactions.rowKey}
              rowURL={transactions.rowURL}
              pageSize={paging?.page_size || 0}
              totalItems={paging?.total_items || 0}
              emptyStateHeading={transactions.emptyStateHeading || ''}
              emptyStateMessage={transactions.emptyStateMessage || ''}
              filterType="bulk-payouts"
              filterHasAdvancedFilter={false}
              filterKeywordPlaceholder="Search Transaction"
              filterTotalCount={paging?.total_items}
              filterActiveCurrency={activeCurrencyTab}
              filterShowExport={false}
              hideTable={data?.length === 0}
            >
              {transactions.fields}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function BulkPayoutContainer() {
  return (
    <div className="content-i">
      <Switch>
        <Route exact path="/dashboard/bulk-payouts" component={BulkPayouts} />
        <Route path="/dashboard/bulk-payouts/:id">
          <BulkTransactionList />
        </Route>
      </Switch>
    </div>
  );
}
