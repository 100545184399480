import React from 'react';
import { useQuery } from 'react-query';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import CustomisedIframe from '+shared/CustomisedIframe';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { IMetabaseData, metaBaseModeType } from '+types';
import { isAllowed } from '+utils';

const api = new APIRequest();
function PayInMetricComponent() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const { data, refetch, isFetching } = useQuery<IMetabaseData>(
    ['PAY_INS_METRICS'],
    () => api.getMetaBaseData('payins' as metaBaseModeType),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `'There has been an error fetching payin metabase data `,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: isAllowed(userAccess, ['metabase.view']) as boolean
    }
  );

  return (
    <>
      {isFetching && <LoadingPlaceholder type="text" content={2} rows={8} />}
      {data?.urls && data.urls.length > 0
        ? data.urls.map((url: string) => (
            <div key={url}>
              <CustomisedIframe src={url} title={`payins-metabase-${url}`} sandbox="allow-scripts allow-same-origin" />
            </div>
          ))
        : !isFetching && <EmptyStateComponent message="There are no metrics data yet" heading={'No records yet'} />}
    </>
  );
}
export default PayInMetricComponent;
