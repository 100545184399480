/* eslint-disable no-nested-ternary */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useSearchQuery, useSetUserAccess } from '+hooks';
import { payOutsTabs } from '+types';
import { capitalizeRemovedash, isAllowed } from '+utils';

import PayOutMetricComponent from './components/PayOutMetric';
import PayOutTransaction from './components/PayOutTransaction';
import PayOutDetails from './PayoutDetails';

function PayoutComponent() {
  const searchQuery = useSearchQuery();
  const tab = searchQuery.value.tab || payOutsTabs.transactions;
  const userAccess = useSetUserAccess();
  const filteredPayOutsTabs = Object.entries(payOutsTabs).filter(([tab]) =>
    tab === 'metrics' ? isAllowed(userAccess, ['metabase.view']) : true
  );
  return (
    <div className="content-i">
      <div className="content-box">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex mb-4">
            <ul className="nav nav-tabs">
              {filteredPayOutsTabs.map(([key, value]) => (
                <li className="nav-item" key={key}>
                  <button
                    type="button"
                    className={`nav-link ${tab === value ? 'active' : ''}`}
                    onClick={() => {
                      searchQuery.setQuery({ tab: value }, true);
                    }}
                  >
                    {capitalizeRemovedash(value)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </section>
        {tab === payOutsTabs.metrics && <PayOutMetricComponent />}
        {tab === payOutsTabs.transactions && <PayOutTransaction />}
      </div>
    </div>
  );
}

export default function PayOutPage() {
  return (
    <div className="checkouts-container">
      <Switch>
        <Route exact path="/dashboard/payouts" component={PayoutComponent} />
        <Route exact path="/dashboard/payouts/:id">
          <PayOutDetails />
        </Route>
        <Redirect to="/dashboard/access-denied" />
      </Switch>
    </div>
  );
}
