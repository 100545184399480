import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import ToolTip from '+shared/Tooltip';
import { PayOutType } from '+types';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, switchStatus } from '+utils';

export const payouts = ({
  isUserAbleToUpdateStatus,
  updateTransactionStatusModalState,
  clickedTransactionIds
}: {
  isUserAbleToUpdateStatus: boolean | string;
  clickedTransactionIds: unknown[] | undefined;
  updateTransactionStatusModalState: (state: boolean, iter: PayOutType) => void;
}) => ({
  className: ' --history-table',
  rowURL: '/dashboard/payouts',
  rowKey: 'unique_reference',
  emptyStateHeading: 'No records yet',
  emptyStateMessage: 'There are no payouts yet.',
  annotations: 'transaction(s)',
  fields: (iter: PayOutType) => ({
    data: {
      status: (
        <>
          <span className={`status-pill smaller ${switchStatus(iter.status === 'requires_auth' ? 'pending' : iter.status)}`} />
          <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
          {iter.status === 'processing' &&
            ['mobile_money', 'bank_account'].includes(iter.payment_destination_type) &&
            !clickedTransactionIds?.includes(iter.processor_reference) &&
            isUserAbleToUpdateStatus && (
              <button
                onClick={e => {
                  e.stopPropagation();
                  updateTransactionStatusModalState(true, iter);
                }}
                type="button"
                className="status-update-icon"
                aria-label="Update transaction status"
              >
                <Icon name="settings" />
              </button>
            )}
          {clickedTransactionIds?.includes(iter.processor_reference) && iter.status === 'processing' && (
            <ToolTip
              type="processing"
              message={
                <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
              }
              centered
            >
              <span className="rotate">
                <Icon name="loading" />
              </span>
            </ToolTip>
          )}
        </>
      ),
      transaction_id: <span className="trxn-id">{iter.reference}</span>,
      merchant: <span className="merch-name">{capitalize(iter?.account?.name || 'Not Available')}</span>,
      type: <span>{iter.channel === 'web' ? 'Withdrawal' : 'Payout'}</span>,
      'Date / Time': (
        <>
          <span>{getDate(iter.transaction_date)}</span>
          <span className="annotation">{getTime(iter.transaction_date)}</span>
        </>
      ),
      amount: (
        <>
          <span>
            <strong>{formatAmount(iter.amount)}</strong>
          </span>
          <span className="annotation">{iter.currency}</span>
        </>
      )
    }
  })
});
