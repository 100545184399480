import React from 'react';

import { IAuditLogSummaryProps } from '+types';
import { capitalize } from '+utils';

import './index.scss';

const displayObjectProperties = (obj: Record<string, string | number>) => {
  return Object.entries(obj).map(([property, value]) => {
    const formattedValue = value;
    const displayKey = property;

    return (
      <li key={property}>
        <strong>{capitalize(displayKey)}:</strong> <span>{capitalize(formattedValue as string)}</span>
      </li>
    );
  });
};

const AuditLogSummary: React.FC<IAuditLogSummaryProps> = ({ logData }) => {
  const { client, device, os } = logData.metadata?.device_info || {};
  const { location } = logData.metadata || {};

  const syntaxHighlight = (json: Record<string, any>) => {
    let jsonString = JSON.stringify(json, null, 4);

    jsonString = jsonString
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/("(.*?)"):/g, '<span class="json-key">$1</span>:')
      .replace(/: "(.*?)"/g, ': <span class="json-string">"$1"</span>')
      .replace(/: (\d+)/g, ': <span class="json-number">$1</span>')
      .replace(/: (true|false)/g, ': <span class="json-boolean">$1</span>')
      .replace(/: null/g, ': <span class="json-null">null</span>');

    return jsonString;
  };
  console.log('l', logData);

  return (
    <div className="audit-summary">
      <div className="summary-container">
        <h3>Summary</h3>
        <div className="summary">
          <ul>
            <li>
              <strong>Description:</strong> <span>{capitalize(logData?.description)}</span>
            </li>
            {logData?.metadata != null && (
              <>
                {client && displayObjectProperties(client)}
                {device && displayObjectProperties(device)}
                {os && displayObjectProperties(os)}
                {location && displayObjectProperties(location)}
              </>
            )}
            {logData?.state?.current && (
              <li>
                <strong>Previous State</strong>{' '}
                <span>{capitalize(logData?.state?.current?.status || logData?.state?.current?.tier_level)}</span>
              </li>
            )}
            {logData?.state?.previous && (
              <li>
                <strong>Current State</strong>{' '}
                <span>{capitalize(logData?.state?.previous?.status || logData?.state?.current?.tier_level)}</span>
              </li>
            )}
            {logData?.state?.previous?.approved_amount && (
              <li>
                <strong>Approved Amount</strong> <span>{logData?.state?.previous?.approved_amount}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="summary-container">
        <h3>Event</h3>
        <div className="summary">
          <h4 className="json-header">JSON</h4>
          {logData?.state && <pre className="json-container" dangerouslySetInnerHTML={{ __html: syntaxHighlight(logData?.state) }} />}
          {logData?.metadata?.other_info && (
            <pre className="json-container" dangerouslySetInnerHTML={{ __html: syntaxHighlight(logData?.metadata?.other_info) }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuditLogSummary;
