import React from 'react';

import Table from '+containers/Dashboard/Shared/Table';
import { useSetUserAccess } from '+hooks';
import { IdentityTablePropsT, IIdentityMerchant } from '+types/identity';
import { capitalizeFirst, getDate, getTime, history, isAllowed } from '+utils';

import OfficeIcon from '+assets/img/dashboard/office-icon.svg';

import './index.scss';

const countries = [
  { value: 'ng', label: 'Nigeria' },
  { value: 'gh', label: 'Ghana' },
  { value: 'ke', label: 'Kenya' },
  { value: 'za', label: 'South Africa' }
];

export default function MerchantsTable({ resolvedData, isFetching, refetch, exportData }: IdentityTablePropsT<IIdentityMerchant>) {
  const userAccess = useSetUserAccess();

  const canShowExport = isAllowed(userAccess, ['identity_merchants.export']);
  const canViewMerchantDetails = isAllowed(userAccess, ['identity_merchant_details.view']);

  const getDetailsLink = (reference: string) => `/dashboard/identity/merchants/${reference}`;
  const handleClick = (reference: string) => {
    if (canViewMerchantDetails) {
      history.push(getDetailsLink(reference));
    }
  };

  const getRegions = (regions: string[]) => {
    if (!regions || regions?.length <= 0) return 'N/A';
    const regionNames = regions.map(region => {
      return countries.find(country => country.value === region)?.label;
    });
    return regionNames.join(', ');
  };

  const renderFields = (merchant: IIdentityMerchant[]) => {
    return (
      <div className="nav-content active">
        <div className="element-box-tp">
          <div>
            {merchant?.map(each => {
              return (
                <div
                  key={`merchant_${each.name}`}
                  className="div-table --row imt"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleClick(each.reference)}
                  onKeyDown={() => handleClick(each.reference)}
                >
                  <div>
                    <span className="body-row-header">Merchant:</span>
                    <img src={OfficeIcon} alt="Office Icon" />

                    <span className="imt__merchant-name">{capitalizeFirst(each?.name)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Service Status:</span>
                    <span className={`identity__service-status --${each?.active ? 'enabled' : 'disabled'}`} />
                    <span>{each?.active ? 'Enabled' : 'Disabled'}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Enabled Region:</span>
                    <span>{getRegions(each?.enabled_countries)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Configuration:</span>
                    <span>{each?.has_custom_setting ? 'Custom' : 'Default'}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Date Added:</span>
                    {each.date_created ? (
                      <>
                        {' '}
                        <span>{getDate(each?.date_created)}</span>
                        <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                          {getTime(each?.date_created)}
                        </span>
                      </>
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="element-box-tp mt-5">
      <Table
        className={'imt'}
        tableHeadings={['Merchant', 'Service Status', 'Enabled Region', 'Configuration', 'Date Added']}
        totalItems={resolvedData?.paging ? resolvedData.paging.total_items : 0}
        pageSize={resolvedData?.paging ? resolvedData.paging.page_size : 0}
        annotation={'merchants'}
        emptyStateHeading={`There are no merchants yet.`}
        filterExportName={'Export Identity Merchants'}
        filterName={`All merchants with access (${resolvedData?.paging?.total_items || 0})`}
        filterExportAction={exportData}
        type="identity-merchants"
        filterKeywordPlaceholder="Search by Merchant ..."
        filterTotalCount={resolvedData?.paging?.total_items}
        loading={isFetching}
        data={resolvedData?.data || []}
        filterHasAdvancedFilter
        filterShowExport={canShowExport as boolean}
        hasPagination
      >
        {renderFields(resolvedData?.data)}
      </Table>
    </section>
  );
}
