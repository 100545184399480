import React from 'react';
import { Link } from 'react-router-dom';

import { LienDataType, LienStageType, MerchantBalanceHistoryType, MerchantReserveHistoryType } from '+types';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, isAllowed, switchStatus } from '+utils';

import LienOptions from '../components/LienOptions';

export const tabType = {
  balance: 'Balance',
  reserve: 'Reserve',
  lien: 'Lien'
} as const;

export type TabType = (typeof tabType)[keyof typeof tabType];

const filterHistory = (type: 'settlement' | 'lien' | string, description: string, reference: string, direction: 'credit' | 'debit') => {
  const notAvailable = [undefined, null, '', ' '];
  if (description?.startsWith('Settlement')) {
    return (
      <>
        Settlement for{' '}
        <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
          {reference?.toUpperCase()}
        </Link>
      </>
    );
  }
  if (description?.startsWith('Chargeback') || description?.startsWith('Refund')) {
    const rcDescription = description.split('processed for ');
    return (
      <>
        {rcDescription[0]}
        <span style={{ fontWeight: 600 }}>({reference?.toUpperCase()})</span> processed for -{' '}
        <Link to={`/dashboard/pay-ins/${rcDescription[1]}`} style={{ fontWeight: 600 }}>
          {rcDescription[1]?.toUpperCase()}
        </Link>
      </>
    );
  }
  if (description?.startsWith('overpayment') || description?.startsWith('underpayment')) {
    const ovDescription = description.split('for ');
    return (
      <>
        Reversal for <span style={{ textTransform: 'capitalize' }}>{ovDescription[0]}</span> processed for -{' '}
        <Link to={`/dashboard/pay-ins/${ovDescription[1]}`} style={{ fontWeight: 600 }}>
          {ovDescription[1]?.toUpperCase()}
        </Link>
      </>
    );
  }
  if (description?.startsWith('Rolling')) {
    // eslint-disable-next-line prefer-regex-literals
    const rcDescription = description.split(new RegExp(' for ', 'i'));
    return (
      <>
        {capitalize(rcDescription[0].replace('Rolling', ''))}
        {' for '}
        <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
          {reference?.toUpperCase()}
        </Link>
      </>
    );
  }
  if (notAvailable.includes(description)) return 'Not Available';
  if (type === 'lien') {
    return (
      <>
        {description} -{' '}
        <Link to={`/dashboard/pay-ins/lien/${reference}`} style={{ fontWeight: 600 }}>
          {reference?.toUpperCase()}
        </Link>
      </>
    );
  }
  return (
    <>
      {description}
      {!notAvailable.includes(reference) && (
        <>
          {' - '}
          {type === 'settlement' ? (
            <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
              {reference?.toUpperCase()}
            </Link>
          ) : (
            <Link to={`/dashboard/${direction === 'credit' ? 'pay-ins' : 'payouts'}/${reference}`} style={{ fontWeight: 600 }}>
              {reference?.toUpperCase()}
            </Link>
          )}
        </>
      )}
    </>
  );
};

export const switchBalanceHistory = (
  activetab: TabType,
  handleLienOption: (type: LienStageType, amount: string, reference: string) => void,
  userAccess: Record<string, boolean> | null
) => {
  switch (activetab) {
    case tabType.balance:
      return {
        className: '--balance-history',
        emptyStateHeading: 'No entries yet',
        emptyStateMessage: 'There are no results yet.',
        fields: (each: MerchantBalanceHistoryType) => ({
          data: {
            'Date/Time': (
              <>
                <span>{getDate(each.history_date)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.history_date)}
                </span>
              </>
            ),
            Details: <span>{filterHistory(each.source_type, each.description, each.source_reference, each.direction)}</span>,
            Amount: (
              <span style={{ fontWeight: 600, color: each.direction === 'debit' ? '#F32345' : '#24B314' }}>{`${
                each.direction === 'debit' ? '-' : '+'
              }${formatAmount(each.amount)}`}</span>
            ),
            balance_after: <span className="grey-text">{formatAmount(each.balance_after)}</span>
          }
        })
      };
    case tabType.reserve:
      return {
        className: '--balance-history',
        emptyStateHeading: 'No entries yet',
        emptyStateMessage: 'There are no results yet.',
        fields: (each: MerchantReserveHistoryType) => ({
          data: {
            'Date/Time': (
              <>
                <span>{getDate(each.history_date)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.history_date)}
                </span>
              </>
            ),
            Details: (
              <span style={{ maxWidth: '500px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {filterHistory(each.source_type, each.description, each.transaction_reference, each.direction)}
              </span>
            ),
            Amount: (
              <span style={{ fontWeight: 600, color: each.direction === 'debit' ? '#F32345' : '#24B314' }}>{`${
                each.direction === 'debit' ? '-' : '+'
              }${formatAmount(each.amount)}`}</span>
            ),
            balance_after: <span className="grey-text">{formatAmount(each.balance_after)}</span>
          }
        })
      };
    case tabType.lien:
      return {
        className: '--lien-history',
        emptyStateHeading: 'No entries yet',
        emptyStateMessage: 'There are no results yet.',
        fields: (each: LienDataType) => ({
          data: {
            Status: (
              <>
                <span className={`status-pill smaller ${switchStatus(`lien_${each.status}`)}`} />
                <span>{capitalizeRemovedash(each.status === 'withheld' ? 'debited' : each.status)}</span>
              </>
            ),
            'Lien ID': (
              <span>
                {isAllowed(userAccess, ['lien_details.view']) && (
                  <Link to={`/dashboard/pay-ins/lien/${each.reference}`} style={{ fontWeight: 600 }}>
                    {each.reference?.toUpperCase()}
                  </Link>
                )}
              </span>
            ),
            'Transaction ID': (
              <span>
                {each.source_reference ? (
                  <Link to={`/dashboard/pay-ins/${each.source_reference}`} style={{ fontWeight: 600 }}>
                    {each.source_reference?.toUpperCase()}
                  </Link>
                ) : (
                  'N/A'
                )}
              </span>
            ),
            'Initiated by': (
              <span className="lien-initiator">
                {each?.event_history ? capitalizeRemovedash(each?.event_history[0]?.admin_name) : 'N/A'}
              </span>
            ),
            'Date/Time': (
              <>
                <span>{getDate(each.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.created_at)}
                </span>
              </>
            ),
            Amount: <span>{formatAmount(Number(each.amount))}</span>,
            Action:
              isAllowed(userAccess, ['lien.update']) && !['withheld', 'released'].includes(each.status) ? (
                <LienOptions onDisplayModal={(value: LienStageType) => handleLienOption(value, each.amount, each.reference)} />
              ) : null
          }
        })
      };
  }
};
