import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { ChargebackActionsType, ChargebackStatusType, CurrencyType, IChargebackTransactionDetails, NonConfirmActionType } from '+types';
import { chargebackStatusTagConfig, history, isAllowed } from '+utils';

import ChargebackActionModal from './ChargebackActionModal';
import {
  generateCardholderInfoFrom,
  generateMoreDetailFrom,
  generateSummaryFrom,
  getActionButtonsBy,
  reduceDocumentsFrom
} from './chargebackTransactionDetailsData';
import DownloadDocuments from './DownloadDocuments';
import Timeline from './Timeline';

const api = new APIRequest();

export default function ChargebackTransactionDetails() {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const [action, setAction] = useState<NonConfirmActionType | null>(null);
  const userAccess = useSetUserAccess();

  const { data, refetch, isLoading } = useQuery<IChargebackTransactionDetails>(
    `TRANSACTION_DETAILS_${id}`,
    () => api.fetchSingleIssuanceChargeback(id),
    {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
          type: 'danger',
          action: { name: 'retry', action: () => refetch() }
        });
        history.goBack();
      }
    }
  );

  const actionButtons = getActionButtonsBy(data?.status as ChargebackStatusType, (value: ChargebackActionsType) => setAction(value));
  const summaries = generateSummaryFrom(data as IChargebackTransactionDetails);
  const moreDetails = generateMoreDetailFrom(data as IChargebackTransactionDetails);
  const cardholderInfo = generateCardholderInfoFrom(data as IChargebackTransactionDetails);
  const documentsData = reduceDocumentsFrom(data?.status_history);

  return (
    <>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={data?.amount as string}
          currency={data?.currency}
          statusLabels={[
            {
              status: chargebackStatusTagConfig[data?.status as ChargebackStatusType]?.name,
              statusBg: chargebackStatusTagConfig[data?.status as ChargebackStatusType]?.backgroundColor,
              statusColor: chargebackStatusTagConfig[data?.status as ChargebackStatusType]?.color
            }
          ]}
          actionButtons={isAllowed(userAccess, ['card_issuance_chargeback.update']) ? actionButtons : []}
          summaries={summaries}
          isLoading={isLoading}
        />

        <TransactionDetails.Section isLoading={isLoading} heading="More Transaction Details" summaries={moreDetails} />

        <TransactionDetails.Section isLoading={isLoading} heading="Cardholder's Information" summaries={cardholderInfo} />

        <TransactionDetails.Section isLoading={isLoading} heading="Chargeback Timeline">
          <Timeline data={data?.status_history as IChargebackTransactionDetails['status_history']} />
        </TransactionDetails.Section>

        <TransactionDetails.Section isLoading={isLoading} heading="Chargeback Documents">
          <DownloadDocuments data={documentsData} />
        </TransactionDetails.Section>
      </TransactionDetails>

      {action !== null ? (
        <ChargebackActionModal
          chargebackDetails={{
            status: data?.status as string,
            currency: data?.currency as CurrencyType,
            reference: data?.reference as string,
            chargebackAmount: (data?.re_escalated_amount as unknown as string) ?? (data?.amount as string),
            acceptedAmount: data?.accepted_amount as string
          }}
          action={action}
          close={() => setAction(null)}
          refetchTransaction={refetch}
        />
      ) : null}
    </>
  );
}
