/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Storage } from '+/services/storage-services';
import { useFeedbackHandler, useLargeExportDownloader, useSearchQuery, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import { FileFormatType, payOutsTabs, PayOutType } from '+types';
import {
  APIDownload,
  filteredOutObjectProperty,
  formatAllTime,
  getDate,
  getPresentDate,
  isAllowed,
  queriesParams,
  storageDataKey
} from '+utils';
import useStore from '+zustandStore';

import TransactionStatusModal from '../../Shared/TransactionStatus';
import { payouts } from './PayOutHelper';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function PayOutTransaction() {
  const { feedbackInit } = useFeedbackHandler();

  const availableCurrencies = Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES) as unknown as string[];
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const searchQuery = useSearchQuery();
  const activeCurrencyTab = searchQuery.value.currency || 'NGN';
  const tab = searchQuery.value.tab || payOutsTabs.transactions;
  const limit = searchQuery.value.limit || '10';
  const dateFrom = searchQuery.value.dateFrom || getPresentDate().dateFrom;
  const dateTo = searchQuery.value.dateTo || getPresentDate().dateTo;
  const status = searchQuery.value.status || [];
  const sortingParams = {
    dateFrom: formatAllTime(dateFrom),
    dateTo: formatAllTime(dateTo),
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      queriesParams.totalItems
    ])
  };
  const userAccess = useSetUserAccess();
  const { profile } = useStore(state => state);

  const { getDownload } = useLargeExportDownloader('Payouts');

  useEffect(() => {
    getDownload();
  }, []);

  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();

  useEffect(() => {
    if (state.clickedTransactionIds?.length) {
      refetch();
    }
  }, [state.clickedTransactionIds]);

  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(
    [
      'PAYOUT_TRANSACTIONS',
      filteredOutObjectProperty(searchQuery.value, [queriesParams.totalItems, queriesParams.page]),
      activeCurrencyTab
    ],
    () => api.getTransactions('payouts', limit, sortingParams, activeCurrencyTab),
    {
      keepPreviousData: true,
      onError: () => {
        let message = 'There has been an error getting your payouts.';
        if (searchQuery.value?.sorterType)
          message = `There has been an error so we could not find any results for your ${searchQuery.value?.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: tab === payOutsTabs.transactions
    }
  );

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging;
  const exportFile = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportTransactions('payouts', sortingParams, format, activeCurrencyTab, fieldsToExport);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'excel';
        APIDownload(res, `Payouts at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>payouts transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your payouts`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const payoutTableConfig = payouts({
    isUserAbleToUpdateStatus: isAllowed(userAccess, ['payouts_status.update']),
    updateTransactionStatusModalState: updateTransactionStatusModalState,
    clickedTransactionIds: state.clickedTransactionIds
  });
  const tableDataKeys = Object.keys(payoutTableConfig.fields({} as PayOutType).data);
  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />

      <section className="os-tabs-w">
        <div className="os-tabs-controls os-tabs-complex mb-4">
          <ul className="nav nav-tabs">
            {availableCurrencies?.map(currency => (
              <li className="nav-item" key={currency}>
                <button
                  type="button"
                  className={`nav-link ${activeCurrencyTab === currency ? 'active' : ''}`}
                  onClick={() => searchQuery.setQuery({ currency }, true)}
                >
                  {currency}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <div className="row">
        <div className="col-sm-12">
          <Table
            className={payoutTableConfig.className || ''}
            loading={isFetching}
            data={data}
            renderFields
            hasPagination
            tableHeadings={tableDataKeys}
            annotation={payoutTableConfig.annotations}
            rowKey={payoutTableConfig.rowKey}
            rowURL={payoutTableConfig.rowURL}
            emptyStateHeading={payoutTableConfig.emptyStateHeading || ''}
            tableWrapperClassName="table-responsive"
            emptyStateMessage={payoutTableConfig.emptyStateMessage || ''}
            filterType="pay-outs"
            filterExportAction={exportFile}
            filterActiveCurrency={activeCurrencyTab}
            filterShowExport={isAllowed(userAccess, ['payouts.export']) as boolean}
            cursors={paging}
          >
            {payoutTableConfig.fields}
          </Table>
        </div>
      </div>

      {state.openTransactionStatusModal && (
        <TransactionStatusModal
          activeTransaction={state.activeTransaction}
          updateModalState={updateTransactionStatusModalState}
          triggerProcessingLoader={handleProcessingLoader}
          transactionType="payout"
        />
      )}
    </>
  );
}
export default PayOutTransaction;
