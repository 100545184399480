/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import Icon from '+shared/Icons';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { ScrollToTopSection } from '+shared/ScrollToTop';
import {
  ActionButtonType,
  ChildrenGeneratorFnType,
  DisputesType,
  IBaseDisputesData,
  IDisputes,
  StatusItemType,
  SummaryGeneratorFnType,
  SummaryItemType
} from '+types';
import { history } from '+utils';

import ChargebacksTab from './components/ChargebacksTab';
import RefundsTab from './components/RefundsTab';
import ReversalsTab from './components/ReversalsTab';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

export default function TransactionDetails({ children }: { children: React.ReactNode }) {
  return (
    <div className="transaction-details-comp">
      <div>
        <button type="button" className="btn btn-link go-back-btn" onClick={() => history.goBack()}>
          <i className="os-icon os-icon-arrow-left7" />
          <span>Go Back</span>
        </button>
      </div>

      {children}
      <div className="scroll-to-top cluster justify-center items-center">
        <ScrollToTopSection />
      </div>
    </div>
  );
}

const renderActionButtons = (actionButtons: Array<ActionButtonType>) => {
  return (
    <ul className="trxn-layout-action-buttons cluster" aria-label="Perform further actions on this transaction">
      {actionButtons.map(
        ({ children, className = '', onClick, variant = 'primary', disabled, hidden = false, iconAfter, iconBefore }, index) => {
          return hidden ? null : (
            <li key={index}>
              <button
                className={`btn btn-${variant} cluster justify-center items-center ${className}`}
                type="button"
                onClick={onClick}
                disabled={disabled}
              >
                {iconBefore}&nbsp;
                {children}&nbsp;
                {iconAfter}
              </button>
            </li>
          );
        }
      )}
    </ul>
  );
};

const renderStatusLabels = (values: Array<StatusItemType>) => {
  return values.map(value => {
    if (value.hide) return null;
    return (
      <span
        className="status"
        aria-label="Transaction Status"
        style={{ background: value.statusBg, color: value.statusColor }}
        key={value.statusBg}
      >
        {value.status}
      </span>
    );
  });
};

TransactionDetails.Header = ({
  heading = '--',
  currency,
  actionButtons,
  summaries,
  isLoading,
  statusLabels = [{ status: 'pending', statusBg: '#FFF8E1', statusColor: '#FA9500' }]
}: {
  heading: string | number;
  currency?: string;
  actionButtons?: Array<ActionButtonType>;
  summaries: Array<SummaryItemType>;
  isLoading: boolean;
  statusLabels: Array<StatusItemType>;
}) => {
  return (
    <section aria-live="polite" className="trxn-layout-top-section">
      <h2 className="sr-only">Transaction overview</h2>

      {isLoading ? (
        <>
          <span className="sr-only">Overview is Loading</span>
          <div role="presentation">
            <LoadingPlaceholder type="text" content={4} />
          </div>
        </>
      ) : (
        <>
          <span aria-live="polite" className="sr-only">
            Overview has loaded
          </span>

          <div className="trxn-layout-heading cluster justify-between">
            <div className="cluster gap-xs justify-center items-baseline heading-and-status">
              <div className="cluster gap-xs nowrap items-baseline">
                <span className="heading">{heading}</span>
                <span className="currency" aria-label="currency">
                  {currency}
                </span>
              </div>
              | <div className="labels">{renderStatusLabels(statusLabels)}</div>
            </div>

            {actionButtons ? renderActionButtons(actionButtons) : null}
          </div>

          <div className="trxn-layout-summary fade-in cluster items-start">
            {summaries?.length > 0 &&
              summaries.map((summary, index) => {
                return summary.hidden ? null : (
                  <div className="summary-item" key={index}>
                    <div className="summary-label" id={`${index}`}>
                      {summary.label}
                    </div>
                    <div className="summary-value" aria-labelledby={`${index}`}>
                      {summary.value}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </section>
  );
};

TransactionDetails.Section = ({
  heading,
  summaries = [],
  children,
  isLoading,
  showLink = false,
  linkText = 'View Breakdown',
  handleLinkClick,
  showLearnMoreBtn = false,
  openLearnMoreModal
}: {
  heading: React.ReactNode;
  summaries?: Array<SummaryItemType>;
  children?: React.ReactNode;
  isLoading: boolean;
  showLink?: boolean;
  linkText?: React.ReactNode;
  handleLinkClick?: () => void;
  showLearnMoreBtn?: boolean;
  openLearnMoreModal?: () => void;
}) => {
  if (isLoading)
    return (
      <>
        <span className="sr-only">Summary is Loading</span>
        <div role="presentation">
          <LoadingPlaceholder type="text" content={4} />
        </div>
      </>
    );

  const List = (
    <ul>
      {summaries?.length > 0 &&
        summaries.map((summary, index) => {
          return summary.hidden ? null : (
            <li className="section-item cluster nowrap" key={index}>
              <div className="section-item-label" id={`${index}`}>
                {summary.label}
              </div>
              <div className="section-item-value" aria-labelledby={`${index}`}>
                {summary.value}
              </div>
            </li>
          );
        })}
    </ul>
  );

  return (
    <>
      <span className="sr-only">Summary has loaded</span>
      <section className="trxn-layout-section">
        <h2 className="sr-only">{heading}</h2>
        <div className="section-title">
          <div className="section-title__left">
            <p>{heading}</p>
            {showLearnMoreBtn && (
              <button type="button" onClick={openLearnMoreModal}>
                Learn More
                <img src={InfoIcon} alt="info" />
              </button>
            )}
          </div>

          {showLink && (
            <button onClick={handleLinkClick} className="view-breakdown" type="button">
              {linkText} <Icon name="arrowUpRight" style={{ marginLeft: 6 }} />
            </button>
          )}
        </div>
        <div className="section-list fade-in">{children ?? List}</div>
      </section>
    </>
  );
};

TransactionDetails.Disputes = <S extends IBaseDisputesData, T extends IBaseDisputesData, U extends IBaseDisputesData>({
  tabs = ['refunds', 'reversals', 'chargebacks'],
  currency = 'USD',
  disputesGenerators = defaultDisputesGenerators,
  summaryGenerators = defaultSummaryGenerators,
  childrenGenerators = defaultChildrenGenerators,
  isPayOut = false
}: IDisputes<S, T, U>) => {
  const [activeTab, setActiveTab] = useState<DisputesType>(isPayOut ? tabs[1] : tabs[0]);

  const disputeComponentsByType: Record<DisputesType, React.ReactNode> = {
    refunds: (
      <RefundsTab
        currency={currency}
        data={disputesGenerators?.refunds as Array<S>}
        summaryGenerator={summaryGenerators.refunds as SummaryGeneratorFnType<S>}
        childrenGenerator={childrenGenerators.refunds as ChildrenGeneratorFnType<S>}
      />
    ),
    chargebacks: (
      <ChargebacksTab
        currency={currency}
        data={disputesGenerators?.chargebacks as Array<T>}
        summaryGenerator={summaryGenerators.chargebacks as SummaryGeneratorFnType<T>}
        childrenGenerator={childrenGenerators.chargebacks as ChildrenGeneratorFnType<T>}
      />
    ),
    reversals: (
      <ReversalsTab
        currency={currency}
        data={disputesGenerators?.reversals as Array<U>}
        summaryGenerator={summaryGenerators.reversals as SummaryGeneratorFnType<U>}
        childrenGenerator={childrenGenerators.reversals as ChildrenGeneratorFnType<U>}
      />
    )
  };

  const tabsList = (
    <ul className="dispute-tabs switcher">
      {tabs.map(tab => (
        <li className="cluster justify-center items-center" key={tab}>
          <button
            type="button"
            className={`dispute-tab cluster justify-between items-center font-weight-bold ${tab === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
            key={tab}
            id={`${tab}-controller`}
          >
            <div>
              <span>{tab}</span> <span>({disputesGenerators?.[tab]?.length ?? 0})</span>
            </div>
            <span className={`tab-icon ${tab === activeTab ? 'active' : ''}`} />
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="trxn-layout-disputes with-sidebar nogap">
      <div className="sidebar">{tabsList}</div>
      <div className="not-sidebar" aria-labelledby={`${activeTab}-controller`} id={`${activeTab}-panel`} role="tabpanel">
        {disputeComponentsByType[activeTab]}
      </div>
    </div>
  );
};

const defaultDisputesGenerators = {
  refunds: [],
  reversals: [],
  chargebacks: []
};

const defaultSummaryGenerators = {
  refunds: () => [],
  reversals: () => [],
  chargebacks: () => []
};

const defaultChildrenGenerators = {
  refunds: () => null,
  reversals: () => null,
  chargebacks: () => null
};
