import React, { useRef } from 'react';

import Modal from './Modal';

import KpyLoader from '+assets/img/dashboard/kpy-loader.svg';

export default function FileViewer({
  showView,
  setShowView,
  url,
  size = 'lg',
  viewInModal = true
}: {
  setShowView: (e: boolean) => void;
  url: string;
  size?: 'sm' | 'lg' | 'md';
  viewInModal?: boolean;
  showView: boolean;
}) {
  const loaderRef = useRef<HTMLImageElement>(null);

  if (!showView) return null;

  const fileView = (
    <section className="iframe-container">
      <img src={KpyLoader} className="image-loader" alt="loader" ref={loaderRef} width="40px" />
      <img
        className="image-viewer"
        alt="file-viewer"
        src={url}
        onLoad={() => {
          if (loaderRef.current) loaderRef.current.style.display = 'none';
        }}
      />
    </section>
  );

  return viewInModal ? (
    <Modal
      close={() => setShowView(false)}
      size={size}
      heading="File Viewer"
      content={fileView}
      showButtons={false}
      description=""
      secondButtonText=""
    />
  ) : (
    <section data-testid="no-modal-doc-view" className="no-modal-doc-view">
      <button data-testid="close-no-modal-doc-view" type="button" onClick={() => setShowView(false)}>
        <span className="os-icon os-icon-close" />
      </button>
      {fileView}
    </section>
  );
}
