import React, { ReactNode, useState } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { useClickOutside } from '+hooks';
import { LienStageType } from '+types';

import Ellipsis from '+assets/img/dashboard/ellipsis-blue.svg';

import './index.scss';

const LienOptions = ({ onDisplayModal }: { onDisplayModal: (type: LienStageType) => void }) => {
  const [manageModal, setManageModal] = useState(false);

  const toggleManageModal = () => {
    setManageModal(prevState => !prevState);
  };

  const wrapperRef = useClickOutside(() => {
    setManageModal(false);
  });
  const items: Array<{
    onClick: () => void;
    hide: boolean;
    icon: ReactNode;
    title: string;
  }> = [
    {
      onClick: () => {
        onDisplayModal('release');
        toggleManageModal();
      },
      hide: false,
      icon: <Icon name="unlockedPadlock" />,
      title: 'Release Lien'
    },
    {
      onClick: () => {
        onDisplayModal('debit');
        toggleManageModal();
      },
      hide: false,
      icon: <Icon name="lockedPadlock" />,
      title: 'Debit Lien'
    }
  ];
  return (
    <span ref={wrapperRef} className="option-container">
      <span
        role="button"
        data-testid="ellipsis-button"
        onClick={toggleManageModal}
        onKeyDown={toggleManageModal}
        tabIndex={0}
        className="ellipsis"
      >
        <img src={Ellipsis} alt="ellipsis" />
      </span>

      {manageModal && (
        <ul className="option-list  element-box ellipsis__nav">
          {items
            .filter(({ hide }) => !hide)
            .map(item => (
              <li className="option-item ellipsis__item" key={item.title}>
                <button onClick={item.onClick} tabIndex={0} type="button">
                  {item.icon}
                  <span className="option-text">{item.title}</span>
                </button>
              </li>
            ))}
        </ul>
      )}
    </span>
  );
};

export default LienOptions;
