import { CurrencyType } from '+types/utils';

export interface IMerchantData {
  id: number;
  name: string;
  description: string;
  avatar: string | null;
  kora_id: number;
  kora_account_id: number;
  is_active: number;
  email: string;
  risk_level: string;
  type: number;
  kora_core_engine_id: number;
  payment_channel_type: string;
  can_go_live: boolean;
  env: string;
  configuration: string | null;
  display_support_email: boolean;
  status: string;
  kyc_status: string;
  country_id: number;
  sra: string;
  tier_level_id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  country: {
    name: string;
    iso2: string;
    is_active: boolean;
  };
  tier_level: {
    name: string;
    description: string | null;
  };
  default_currency: string;
  website: string;
  support_email: string | null;
  support_phone: string;
  reserved_bank_account: string | null;
  can_do_disbursements: boolean;
  can_do_card_tokenization: boolean;
  can_do_collections: boolean;
  fetching_status: string;
  settlements: {
    [code: string]: {
      destination: string;
      cycle: {
        [features: string]: string | number;
      };
      rolling_reserve: Record<string, Record<string, { [id: string]: number }>>;
      updated_at: string;
    };
  };
}

export type LienStageType = 'place' | 'release' | 'debit' | 'confirm-lien' | '';

export type LienStatusType = 'active' | 'released' | 'withheld';

export type CreateLienDataType = {
  merchant_id: number;
  amount: number;
  reason: string;
  currency: CurrencyType;
};

export type UpdateLienDataType = {
  reason: string;
  status: LienStatusType;
};

export interface ICreateLienData {
  merchant_id: number;
  amount: number;
  reason: string;
  currency: CurrencyType;
  payment_source_reference?: string;
}

export type LienDataType = {
  amount: string;
  created_at: string;
  date_completed: null | string;
  currency: CurrencyType;
  event_history: {
    admin_email: string;
    admin_name: string;
    date: string;
    reason: string;
    status: LienStatusType;
    transaction_reference: string;
    transaction_type: 'disbursement_wallet_available_balance';
  }[];
  merchant: {
    name: string;
  };
  merchant_id: number;
  merchant_name: string;
  reason: string;
  reference: string;
  source_id: null;
  source_reference: string | null;
  source_type: null;
  status: LienStatusType;
  updated_at: string;
};

export type MerchantBalanceType = {
  [k in CurrencyType]: {
    available_balance: number;
    is_default: boolean;
    ledger_balance: number;
    pending_balance: number;
    reserved_balance?: number;
  };
};

export type PaymentSourceType =
  | 'wallet_conversion'
  | 'payout_reversal'
  | 'payment_destination'
  | 'ng_passport'
  | 'ng_pvc'
  | 'ng_nin'
  | 'ng_vnin'
  | 'ng_bvn'
  | 'ng_phone'
  | 'za_said'
  | 'ke_phone'
  | 'ke_passport'
  | 'ke_national_id'
  | 'gh_passport'
  | 'gh_voters_card'
  | 'gh_ssnit'
  | 'gh_drivers_license'
  | 'ke_tax_pin'
  | 'ng_nin_phone'
  | 'payment_source'
  | 'chargeback'
  | 'ng_cac'
  | 'settlement'
  | 'wallet_swap'
  | 'disbursement_wallet'
  | 'refund'
  | 'payment_source_fee'
  | 'rolling_reserve';

export type PaymentDirectionType = 'credit' | 'debit';

export type MerchantBalanceHistoryType = {
  source_type: PaymentSourceType;
  source_reference: string;
  current_balance: string;
  amount: string;
  direction: PaymentDirectionType;
  currency: CurrencyType;
  description: string;
  created_at: string;
  history_date: string;
  transaction_reference: string;
  balance_after: string;
  balance_before: string;
};

export type MerchantReserveHistoryType = {
  source_type: PaymentSourceType;
  amount: string;
  direction: PaymentDirectionType;
  currency: CurrencyType;
  description: string;
  available_on: string;
  history_date: string;
  transaction_reference: string;
  balance_after: string;
  balance_before: string;
};

export interface ILienModalProps {
  merchantId?: number;
  merchantName?: string;
  lienStage: LienStageType;
  setLienStage: (value: LienStageType) => void;
  amount: string;
  lienReference?: string;
  currency: CurrencyType;
  paymentReference?: string;
  balance?: number;
}

export type LienRadioLabelType = 'failure' | 'unsupported' | 'fraud' | 'custom' | 'all';
export type GenerateBalanceStatementModalType = 'generate_balance_statement' | 'custom_date_range';
export interface IGenerateBalanceStatementModalProps {
  isVisible: boolean;
  onClose: () => void;
  currency: CurrencyType;
  merchantId: string;
}
export const generateBalanceStatementModalStages = {
  generate_balance_statement: 'generate_balance_statement',
  custom_date_range: 'custom_date_range'
} as const satisfies Record<GenerateBalanceStatementModalType, GenerateBalanceStatementModalType>;

export const generateBalanceStatementPeriodOptions = [
  { value: 'last_7_days', label: 'Last 7 Days' },
  { value: 'last_1_month', label: 'Last 1 Month' },
  { value: 'last_3_months', label: 'Last 3 Months' },
  { value: 'custom_range', label: 'Custom Range' }
] as const;
export type GenerateBalanceStatementPeriodType = (typeof generateBalanceStatementPeriodOptions)[number]['value'];
export interface IGenerateBalanceStatementForm {
  periodSelected: string;
  isOpen: boolean;
  modal: GenerateBalanceStatementModalType;
  start_date: string;
  end_date: string;
  showCalendarIcon: boolean;
}

export type GenerateBalanceStatementPayLoadType = {
  dateFrom: string;
  dateTo: string;
  currency: CurrencyType;
};
export interface IMerchantBalanceSubActionTab {
  actions: () => void;
  label: string;
  classNames: string;
}
