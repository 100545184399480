import React from 'react';

import {
  ActionButtonType,
  ChildrenGeneratorFnType,
  CurrencyType,
  DisputesType,
  IBaseDisputesData,
  IGenerateMoreDetailFromProps,
  IPayinDetails,
  SummaryItemType,
  TRelatedTransactions
} from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  cardStatus,
  daysfromToday,
  formatAmount,
  formatPAN,
  getDate,
  getDateAndTime,
  getTime,
  history,
  isAllowed,
  payinMethodMapping,
  Status,
  statusPropsMap,
  switchBank,
  switchCard,
  switchChannel,
  switchCurrency,
  switchStatus,
  truncateString
} from '+utils';

import Copyable from '../../Shared/Copyable';
import Icon from '../../Shared/Icons';
import ToolTip from '../../Shared/Tooltip';
import ReversalAttempts from '../components/ReversalAttempts';

import InfoIcon from '+assets/img/dashboard/information-button.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';

const getBankImageByName = (name: string): string => {
  return switchBank[name as unknown as keyof typeof switchBank] || switchBank.default;
};

export function generateSummaryFrom(data: IPayinDetails['data']): Array<SummaryItemType> {
  return [
    {
      label: (
        <div className="summary-label-item">
          <span>Net</span>
          <ToolTip type="net_amount" image={InfoIcon} message={<>This is the amount less fees</>} />
        </div>
      ),
      value: `${formatAmount(data?.amount_paid)} ${data?.currency}`
    },
    {
      label: 'Fee',
      value: `${formatAmount(parseFloat(data?.fee || '0') + parseFloat(data?.vat || '0'))} ${data?.currency}`
    },
    {
      label: 'Date Completed',
      value: data?.completed_at ? `${getDate(data.completed_at)}, ${getTime(data.completed_at)}` : 'Not Available'
    },
    {
      label: 'Merchant',
      value: data?.payment?.account?.name ? (
        <button type="button" className="merchant-name" onClick={() => history.push(`/dashboard/merchant/${data.merchant_id}`)}>
          {data?.payment?.account?.name}
        </button>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Description',
      value: `${data?.channel === 'web' ? 'Balance Top Up' : data?.narration || 'Not Available'}`
    }
  ];
}

export function reprocessButtonAction({ data, onClick }: { data: IPayinDetails['data']; onClick: () => void }): Array<ActionButtonType> {
  return [
    ...(data?.status === 'flagged' && data?.source.virtual_bank_account?.status === 'active'
      ? [
          {
            children: 'Reprocess',
            onClick,
            variant: 'primary' as const,
            disabled: false,
            iconAfter: <i className="os-icon os-icon-corner-up-left" />
          }
        ]
      : [])
  ];
}

export function placeLienModalButton({
  data,
  onClick,
  hasCreateLienAccess
}: {
  data: IPayinDetails['data'];
  onClick: () => void;
  hasCreateLienAccess: boolean | string;
}): Array<ActionButtonType> {
  return [
    ...(data?.status === 'success' && hasCreateLienAccess
      ? [
          {
            children: (
              <>
                <i className="os-icon os-icon-lock" /> Place Lien
              </>
            ),
            onClick,
            disabled: ['active', 'withheld'].includes(data?.lien?.status),
            hidden: false,
            variant: 'danger'
          } as const
        ]
      : [])
  ];
}

export function generateMoreDetailFrom({
  data,
  triggerReasonForFailureModal,
  state,
  updateTransactionStatusModalState,
  userAccess
}: IGenerateMoreDetailFromProps): Array<SummaryItemType> {
  const authExpiryText = () => {
    const diff = daysfromToday(data?.auth_data?.pre_auth_expiry);

    if (diff.includes('in')) return `${diff.split(' ').slice(1).join(' ').split(',').join(' ')} remaining`;
    if (diff.includes('ago')) return 'Expired';
    return diff;
  };

  const paymentSourceType = data?.payment_source_type;
  const amountCharged = data?.amount_charged;
  const amountCollected = data?.amount_collected;

  return [
    {
      label: 'Status',
      value: (
        <>
          <span
            className={`status-pill smaller ${data?.status === 'requires_auth' ? 'yellow' : switchStatus(data?.status)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(data?.status === 'requires_auth' ? 'Pending (Requires Authorization)' : data?.status || 'Not Available')}
          {data?.status === 'processing' &&
            ['mobile_money', 'bank_account'].includes(data?.payment_source_type) &&
            !state.clickedTransactionIds?.includes(data?.processor_reference) &&
            isAllowed(userAccess, ['payins_status.update']) && (
              <button
                aria-label="settings"
                onClick={e => {
                  e.stopPropagation();
                  updateTransactionStatusModalState(true, data);
                }}
                type="button"
                className="status-update-icon"
              >
                <Icon name="settings" />
              </button>
            )}
          {state.clickedTransactionIds?.includes(data?.processor_reference) && data.status === 'processing' && (
            <ToolTip
              type=""
              message={
                <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
              }
              centered
            >
              <span className="rotate">
                <Icon name="loading" />
              </span>
            </ToolTip>
          )}
          {data?.status?.includes('flagged') && (
            <Icon name="warningTriangle" width={16} height={16} style={{ marginLeft: 5, marginBottom: 5 }} />
          )}
        </>
      )
    },
    ...(['expired', 'failed'].includes(data?.status)
      ? [
          {
            label: `Reason for ${data?.status === 'expired' ? 'Expiry' : 'Failure'}`,
            value: (
              <>
                {data?.message}
                <button className="btn btn-link" onClick={triggerReasonForFailureModal}>
                  View all reasons
                </button>
              </>
            )
          }
        ]
      : []),
    {
      label: 'Amount Paid',
      value: `${paymentSourceType === 'bank_transfer' ? formatAmount(amountCollected) : formatAmount(amountCharged)} ${data?.currency}`
    },
    {
      label: 'Amount Expected',
      value: `${formatAmount(amountCharged)} ${data?.currency}`,
      hidden: paymentSourceType !== 'bank_transfer'
    },
    {
      label: 'Currency Charged',
      value: switchCurrency[data?.currency as keyof typeof switchCurrency] || 'Not Available'
    },
    {
      label: 'Korapay ID',
      value: data?.payment?.reference ? (
        <Copyable text={data?.payment?.reference} textModifier={text => truncateString(text, 8)} />
      ) : (
        'Not available'
      )
    },
    {
      label: 'Channel',
      value: switchChannel(data?.channel)
    },
    {
      label: 'Auth Model',
      value: data?.auth_data?.auth_model || 'Not available',
      hidden: paymentSourceType !== 'card'
    },
    {
      label: 'Transaction Type',
      value: data?.channel === 'web' ? 'Withdrawal' : 'Pay-in'
    },
    {
      label: 'Processor',
      value: capitalizeRemovedash(data?.processor) || 'Not available'
    },
    {
      label: 'Processor ID',
      value: data?.processor_reference || 'Not available'
    },
    {
      label: 'Account Name',
      value: data?.payment?.account?.name || 'Not Available'
    },
    {
      label: 'Fee Bearer',
      value: data?.merchant_bears_cost ? 'Merchant' : 'Customer'
    },
    {
      label: (
        <div className="detail-item">
          <p>Approval Code</p>
          <ToolTip image={InfoIcon} message="Also known as ‘Acquirer code" />
        </div>
      ),
      value: data?.meta?.gateway_code ? <Copyable text={data.meta?.gateway_code} /> : 'Not Available'
    },
    {
      label: 'Date Created',
      value: data?.transaction_date ? `${getDate(data?.transaction_date)}, ${getTime(data?.transaction_date)}` : 'Not Available'
    },
    {
      label: 'Date Completed',
      value: data?.completed_at ? `${getDate(data?.completed_at)}, ${getTime(data?.completed_at)}` : 'Not Available'
    },
    {
      label: 'Payment Reversals Type',
      value: data?.payment_reversals_type ? 'Yes' : 'None'
    },
    ...(data?.auth_data?.pre_auth_expiry
      ? [
          {
            label: 'Authorization Expiry',
            value: (
              <>
                `${getDateAndTime(data?.auth_data.pre_auth_expiry)} `
                <i style={{ color: '#A9AFBC', fontStyle: 'italic' }}>({authExpiryText()})</i>
              </>
            )
          }
        ]
      : [])
  ];
}

export function generatePayersInfoFrom(data: IPayinDetails['data']): Array<SummaryItemType> {
  return [
    ...(['expired', 'failed'].includes(data?.status)
      ? [
          {
            label: `Reason for ${data?.status === 'expired' ? 'Expiry' : 'Failure'}`,
            value: <>{data?.message}</>
          }
        ]
      : []),
    {
      label: 'Payment Method',
      value:
        data?.payment_source_type === 'payment_reversal'
          ? 'Payment Reversal'
          : payinMethodMapping[data?.source?.type as keyof typeof payinMethodMapping] || 'Not Available'
    },
    ...(['bank_transfer', 'pay_with_bank'].includes(data?.source?.type)
      ? [
          {
            label: 'Bank',
            value: (
              <>
                <img
                  id="bank-icon"
                  src={getBankImageByName(data?.source?.details?.bank_slug)}
                  alt="bank icon"
                  style={{ marginLeft: data?.source?.details?.bank_name ? 0 : '' }}
                />
                <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
                  {capitalize(data?.source?.details?.bank_name || 'No Bank Information Available')}
                </span>
              </>
            )
          }
        ]
      : []),
    ...(data?.source?.type === 'wallet'
      ? [
          {
            label: (
              <>
                <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
                <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
              </>
            ),
            value: data?.payment?.customer?.email || 'Not provided'
          }
        ]
      : []),

    ...(data?.source?.type === 'card' && data?.source?.details?.masked_pan
      ? [
          {
            label: (
              <span>
                <img
                  src={switchCard[data?.source?.details?.card_type as keyof typeof switchCard]}
                  className="card-logo"
                  alt="card-logo"
                  style={{ width: '1.5rem', marginRight: '8px' }}
                />
                <span style={{ display: 'inline' }}>{capitalize(data?.source?.details?.card_type)}</span>
              </span>
            ),
            value: (
              <span>
                {data?.meta?.card_details?.first_six
                  ? formatPAN(`${data?.meta?.card_details?.first_six}${data?.source.details.masked_pan?.slice(6)}`)
                  : formatPAN(data?.source.details.masked_pan)}
              </span>
            )
          }
        ]
      : []),
    ...(['bank_transfer', 'pay_with_bank', 'wallet', 'card'].includes(data?.source?.type)
      ? [
          {
            label: 'Account Number',
            value: data?.source?.details?.account_number || 'Not Available'
          }
        ]
      : []),
    {
      label: 'Customer Name',
      value: (
        <>
          {data?.source?.type === 'bank_transfer' && <span>{capitalize(data?.source?.details?.account_name || 'Not Available')}</span>}
          {(['card', 'wallet', 'pay_with_bank', 'mobile_money', 'payment_reversal'].includes(data?.source?.type) ||
            data?.payment_source_type === 'payment_reversal') && (
            <span>{capitalize(data?.payment?.customer?.name || 'Not Available')}</span>
          )}
        </>
      )
    },
    ...(['mobile_money'].includes(data?.source?.type)
      ? [
          {
            label: 'Network Provider',
            value: data?.source?.details?.network_provider || 'Operator Information Unavailable'
          }
        ]
      : []),
    ...(['mobile_money'].includes(data?.source?.type)
      ? [
          {
            label: 'Mobile Number',
            value: data?.source?.details?.mobile_number || 'Operator Information Unavailable'
          }
        ]
      : []),
    {
      label: 'Email',
      value: data?.payment?.customer?.email || 'Not provided'
    }
  ];
}

export function generateVirtualAccountInfoFrom(source: IPayinDetails['data']['source']): Array<SummaryItemType> {
  return [
    {
      label: (
        <span>
          <img
            id="bank-icon"
            src={getBankImageByName(source?.details?.bank_slug)}
            alt="bank icon"
            style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
          />
          &nbsp;
          <span style={{ display: 'inline', color: '#636C72' }}>
            {capitalizeRemovedash(source?.virtual_bank_account?.bank_name || 'No bank information Available')}
          </span>
        </span>
      ),
      value: source?.virtual_bank_account?.bank_name ? (
        <span>{source?.virtual_bank_account?.account_number || 'Not Available'}</span>
      ) : (
        'Not Available'
      )
    },
    {
      label: 'Account Name',
      value: capitalize(source?.virtual_bank_account?.account_name || 'Not Available')
    },
    ...(source?.virtual_bank_account?.expiry
      ? [
          {
            label: 'Expired At',
            value: `${getDate(source.virtual_bank_account.expiry)}, ${getTime(source.virtual_bank_account.expiry)}`
          }
        ]
      : [])
  ];
}

export const showRelatedTransactions = ({
  uniqueRef,
  transactions
}: {
  uniqueRef: string;
  transactions: TRelatedTransactions[];
}): React.ReactElement => {
  const extraCardStatus = (status: string) => cardStatus[status as keyof typeof cardStatus];

  return (
    <div className="related-transactions">
      <ul className="related-transactions__list">
        {transactions.map(transaction => (
          <li key={transaction.reference} className="related-transactions__list-item">
            <div>
              {getDate(transaction.transaction_date)} {getTime(transaction.transaction_date)}
            </div>
            <div>
              <span>{uniqueRef}</span>
              <span>{transaction.reference}</span>
            </div>
            <div>
              <span>
                <i className={`status-pill smaller ${switchStatus(transaction.status)}`} />{' '}
                {extraCardStatus(transaction.status) || capitalizeRemovedash(transaction.status)}
              </span>
              <span>
                {transaction.meta?.charge_operation && (
                  <span>
                    {transaction.meta.charge_operation === 'pre_authorization' ? 'Auth' : transaction.meta.charge_operation.split('_')[0]}
                  </span>
                )}
              </span>
            </div>
            <div>
              {formatAmount(transaction.amount)} {transaction.currency}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const summaryGenerators = {
  refunds: (item: any, currency: CurrencyType) => [
    { label: 'Refund ID', value: <Copyable text={item.reference} spanClassName="copyable-blue" /> },
    { label: 'Refund Amount', value: `${item.amount ?? 'Not Available'} ${currency ?? 'Not Available'}` },
    { label: 'Refund Destination', value: 'Not Available' },
    {
      label: 'Refund Status',
      value: (
        <span style={{ color: statusPropsMap[item.status as Status].color ?? '' }}>
          {statusPropsMap[item.status as Status].name ?? 'Not Available'}
        </span>
      )
    },
    {
      label: 'Date Created',
      value: item.created_at ? `${getDate(item.created_at)} | ${getTime(item.created_at)}` : '-- | --',
      hidden: !item.created_at
    },
    {
      label: 'Date Completed',
      value: item.date_completed ? `${getDate(item.date_completed)} | ${getTime(item.date_completed)}` : '-- | --'
    },
    { label: 'Reason for Refund', value: (item?.reversal_reason || item.description) ?? 'Not Available' }
  ],
  chargebacks: (item: any, currency: CurrencyType) => [
    { label: 'Chargeback ID', value: <Copyable text={item?.reference} spanClassName="copyable-blue" /> },
    {
      label: 'Chargeback Amount',
      value: (
        <strong>
          {item?.amount ?? 'Not Available'} {currency ?? 'Not Available'}
        </strong>
      )
    },
    {
      label: 'Escalated Amount',
      value: (
        <strong>
          {item?.amount ?? 'Not Available'} {currency ?? 'Not Available'}
        </strong>
      )
    },
    {
      label: 'Accepted Amount',
      value: (
        <strong>
          {item?.accepted_amount ?? 'Not Available'} {currency ?? 'Not Available'}
        </strong>
      )
    },
    {
      label: 'Chargeback Status',
      value: (
        <span style={{ color: statusPropsMap[item?.status as Status]?.color ?? '' }}>
          {statusPropsMap[item?.status as Status]?.name ?? 'Not Available'}
        </span>
      )
    },
    {
      label: 'Date Escalated',
      value: item?.escalation_date ? `${getDate(item?.escalation_date)} | ${getTime(item?.escalation_date)}` : '-- | --'
    },
    {
      label: 'Date Completed',
      value: item?.actual_resolution_date
        ? `${getDate(item?.actual_resolution_date)} | ${getTime(item?.actual_resolution_date)}`
        : '-- | --'
    }
  ],
  reversals: (item: any) => [
    { label: 'Reason for Reversal', value: capitalize(item.type) },
    { label: 'Reversal Destination', value: item?.destination === 'disbursement_wallet' ? `Balance` : 'Customer' },
    {
      label: 'Reversal Status',
      value: (
        <span style={{ color: statusPropsMap[item.status as Status]?.color ?? '' }}>
          {statusPropsMap[item.status as Status]?.name ?? 'Not Available'}
        </span>
      )
    },
    {
      label: 'Date Created',
      value: item.created_at ? `${getDate(item.created_at)} | ${getTime(item.created_at)}` : '-- | --',
      hidden: !item.created_at
    },
    ...(item.status === 'failed'
      ? [{ label: 'Reason for Failure', value: capitalize(item?.payment_reversal_payout?.message || 'Not available') }]
      : []),
    {
      label: 'Attempts',
      value: (
        <span style={{ color: statusPropsMap[item.status as Status]?.color ?? '' }}>{item?.payment_reversal_payouts?.length || 0}</span>
      )
    }
  ]
};

export const childrenGenerators: Partial<Record<DisputesType, ChildrenGeneratorFnType<IBaseDisputesData>>> = {
  reversals: item => {
    return item?.payment_reversal_payouts.map((attempts, index: number) => (
      <ReversalAttempts key={attempts?.id} attempts={attempts} index={index} item={item} />
    ));
  }
};
