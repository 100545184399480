import React, { useEffect, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useFeedbackHandler, useLargeExportDownloader, useReducerState, useSearchQuery, useSetUserAccess } from '+hooks';
import CurrencyPicker from '+shared/CurrencyPicker';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import ToolTip from '+shared/Tooltip';
import { Nullable } from '+types';
import {
  APIDownload,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  getDate,
  isAllowed,
  logError,
  queriesParams,
  renameObjectKeys
} from '+utils';
import useStore from '+zustandStore';

import { ScrollableTabList, Tab, TabPanel, TabPanels, Tabs } from '../Shared/Tabs';
import BalanceFundingDetails from './balanceApproval/BalanceFundingDetails';
import BillingDetails from './Billing';
import ChargebackTransactionDetails from './Chargebacks/ChargebackTransactionDetails';
import { IssuanceTabList, queryKeyType, summaryInfo, switchTabData } from './data';
import IssuedCardDetails from './IssuedCardDetails';
import IssuedMerchantDetails from './IssuedMerchantDetails';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

function Issuing() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const { profile } = useStore(state => state);
  const { getDownload } = useLargeExportDownloader('resource');
  const availableCurrencies: Nullable<string[]> = ['USD'];

  const [showLargeExportModal, setLargeExportModal] = useState<boolean>(false);
  const permittedTabs = IssuanceTabList.map(tab => {
    if (tab.key === 'card_disputes' && !isAllowed(userAccess, ['card_issuance_chargebacks.view', 'card_issuance_chargebacks.export']))
      return null;
    if (['reserved_cards', 'issued_cards'].includes(tab.key) && !isAllowed(userAccess, ['card_issuance_cards.view'])) return null;
    if (tab.key === 'card_transactions' && !isAllowed(userAccess, ['card_issuance_transactions.view'])) return null;
    if (tab.key === 'issuing_merchants' && !isAllowed(userAccess, ['card_issuing_merchants.view'])) return null;
    if (tab.key === 'billing' && !isAllowed(userAccess, ['card_issuance_billing_list.view'])) return null;
    return tab;
  }).filter(Boolean);

  const activeCurrency = searchQuery.value.currency ?? 'USD';
  const activeTab = searchQuery.value.tab ?? 'overview';
  const currentActiveTab = IssuanceTabList.find(tab => tab.key === activeTab);
  const activeSubTab = currentActiveTab?.subTabs?.[0].key ?? null;
  const currentActiveSubTab = currentActiveTab?.subTabs?.find(tab => tab.key === activeSubTab);
  const page = searchQuery.value.page ?? '1';
  const limit = searchQuery.value.limit ?? 10;
  const status = searchQuery.value.status ?? [];
  const filteredSortingParams = filteredOutObjectProperty(searchQuery.value, [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.sorterType,
    queriesParams.status,
    queriesParams.subTab,
    queriesParams.activeCurrency,
    queriesParams.currency
  ]);

  const computeViewPermissions = () => {
    if (
      activeSubTab === 'issuance_chargebacks' &&
      !isAllowed(userAccess, ['card_issuance_chargebacks.view', 'card_issuance_chargebacks.export'])
    )
      return false;
    if (['reserved_cards', 'issued_cards'].includes(activeTab) && !isAllowed(userAccess, ['card_issuance_cards.view'])) return false;
    if (activeTab === 'card_transactions' && !isAllowed(userAccess, ['card_issuance_transactions.view'])) return false;
    if (activeTab === 'overview' && !isAllowed(userAccess, ['card_issuance_overview.view'])) return false;
    if (activeTab === 'issuing_merchants' && !isAllowed(userAccess, ['card_issuing_merchants.view'])) return false;
    if (activeSubTab === 'billing_history' && !isAllowed(userAccess, ['card_issuance_billing_list.view'])) return false;
    return true;
  };

  const computeExportPermissions = () => {
    if (activeSubTab === 'issuance_chargebacks' && !isAllowed(userAccess, ['card_issuance_chargebacks.export'])) return false;
    if (['reserved_cards', 'issued_cards'].includes(activeTab as string) && !isAllowed(userAccess, ['card_issuance_cards.export']))
      return false;
    if (activeTab === 'card_transactions' && !isAllowed(userAccess, ['card_issuance_transactions.export'])) return false;
    if (activeTab === 'issuing_merchants' && !isAllowed(userAccess, ['card_issuing_merchants.export'])) return false;
    if (activeSubTab === 'billing_history' && !isAllowed(userAccess, ['card_issuance_billing_history.export'])) return false;
    return true;
  };

  const sortingParams = {
    ...renameObjectKeys(filteredSortingParams, {
      dateFrom: 'dateCreatedFrom',
      dateTo: 'dateCreatedTo'
    }),
    status: typeof status === 'string' ? [status] : status
  };
  const currentActiveTabHasAtLeastOneSubTab = (currentActiveTab?.subTabs?.length ?? 0) > 0;

  const [state, setState] = useReducerState({
    summary: [],
    tableData: {},
    isFilterVisible: false,
    sorterType: null,
    exportVisible: false
  });

  const tableDataKeys = Object.keys(state.tableData || {}).length > 0 ? Object.keys(state?.tableData?.fields({})?.data) : [];

  const activeTableRequest = activeSubTab ? currentActiveSubTab : currentActiveTab;
  const { data, isFetching, refetch } = useQuery(
    [activeTableRequest?.queryKey, page, limit, activeCurrency, sortingParams],
    () => activeTableRequest?.query?.(page, limit, sortingParams),
    {
      onError: e => {
        const getErrorMessage = () => {
          if (typeof e.response?.data?.data?.keyword?.message === 'string') return e.response?.data?.data?.keyword?.message;
          if (typeof e.response.data?.message === 'string') return e.response.data?.message;
          return 'There has been an error fetching the requested data';
        };

        feedbackInit({
          message: getErrorMessage(),
          type: 'danger'
        });
      },
      enabled: computeViewPermissions()
    }
  );
  const tabsWithCounters = permittedTabs.flatMap(tab => {
    const result = [];
    if (tab?.hasCounter) {
      result.push({
        queryKey: [tab.queryKey, page, limit, activeCurrency, sortingParams],
        queryFn: () => tab.query
      });
    }
    if (tab?.subTabs && tab?.subTabs.some(subTab => subTab.hasCounter)) {
      const subtabsWithCounter = tab.subTabs
        .filter(subTab => subTab.hasCounter)
        .map(subTab => ({
          queryKey: [
            subTab.queryKey,
            page,
            limit,
            activeCurrency,
            {
              status: [subTab.queryStatus]
            }
          ],
          queryFn: () =>
            subTab.query(page, limit, {
              status: [subTab.queryStatus]
            })
        }));
      result.push(...subtabsWithCounter);
    }
    return result;
  });

  const tabQueries = useQueries(tabsWithCounters) as any;

  const counterGetter = (key?: queryKeyType): number => {
    if (key) {
      const counterKeyIndex = tabsWithCounters.findIndex(query => query.queryKey[0] === key);
      return tabQueries[counterKeyIndex]?.data?.paging.total_items ?? 0;
    }
    return 0;
  };

  useEffect(() => {
    getDownload();
  }, []);

  useEffect(() => {
    setState({ summary: summaryInfo(data, searchQuery.value, tab => searchQuery.setQuery({ tab })) });
  }, [data]);

  useEffect(() => {
    searchQuery.setQuery({ activeCurrency });
    const tab = currentActiveTabHasAtLeastOneSubTab ? activeSubTab : activeTab;
    setState({ tableData: switchTabData(tab, searchQuery.value, data?.paging || {}) });
  }, [activeTab, data]);

  useEffect(() => {
    if (currentActiveTabHasAtLeastOneSubTab) {
      searchQuery.setQuery({ subTab: currentActiveTab?.subTabs?.[0].key as string });
      if (activeSubTab === 'balance_funding') {
        searchQuery.setQuery({ status: 'pending' });
      }
    }
  }, [activeTab]);

  const exportRecords = async (format: string, close: () => void, fieldsToExport: string[]) => {
    try {
      const res: Awaited<Blob> = await (currentActiveSubTab || currentActiveTab)?.query?.(
        page,
        limit,
        sortingParams,
        true,
        format,
        fieldsToExport
      );
      if (res?.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `card-transactions_${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: <> - Successfully exported record.</>,
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your record`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />

      <Tabs defaultValue={activeTab} onChange={tabVal => searchQuery.setQuery({ tab: tabVal, activeCurrency }, true)}>
        <div>
          <div className="tab-box">
            <div>
              <ScrollableTabList>
                {permittedTabs.map(value => {
                  const counter = counterGetter(value?.counterKey);
                  const showCounter = value?.showCounter && counter > 0;
                  return (
                    <Tab value={value?.key as string} key={value?.key}>
                      {value?.label}
                      {showCounter && (
                        <small aria-live="polite" data-testid="counter-bubble" className="notification-bubble ml-2">
                          {counter}
                        </small>
                      )}
                    </Tab>
                  );
                })}
              </ScrollableTabList>
            </div>

            <CurrencyPicker
              options={availableCurrencies}
              onChange={currency => searchQuery.setQuery({ activeCurrency: currency, page: '1' })}
              activeCurrency={activeCurrency}
              id="card-íssuance__currency-switch"
            />
          </div>
        </div>

        <TabPanels>
          {permittedTabs.map(value => (
            <TabPanel key={value?.key} value={value?.key as string}>
              <article>
                <h5 className="form-header">{capitalizeRemovedash(activeTab)}</h5>
                <div className="form-desc no-underline" style={{ width: '60%' }}>
                  {currentActiveTab?.description}
                </div>
              </article>

              <div className="os-tabs-controls os-tabs-complex">
                <ul className="nav nav-tabs" role="tablist">
                  {currentActiveTab?.subTabs?.map(({ label, key }) => {
                    return (
                      <li className="nav-item" key={key} role="tab">
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            searchQuery.setQuery({ limit: '10', page: '1', subTab: key });
                          }}
                          onKeyDown={event => {
                            if (event.key === 'Enter') {
                              searchQuery.setQuery({ limit: '10', page: '1', subTab: key });
                            }
                          }}
                          className={`nav-link ${key === activeSubTab && 'active'}`}
                        >
                          {label}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {activeTab === 'overview' && (
                <section className="info-summary-container">
                  <section className="first-section">
                    {state.summary.map(summary => (
                      <div className="info-summary-item" key={summary.label}>
                        <p className="label">
                          {summary.label}
                          {summary.tooltip && (
                            <span>
                              <ToolTip type={`issuance-summary-${summary.label}`} image={InfoIcon} message={summary.tooltip} />
                            </span>
                          )}
                        </p>
                        <h3 className="value" aria-label="summary header">
                          {summary.value ?? '--'}
                        </h3>
                        <p className="description">{summary.description}</p>
                        {['overview'].includes(activeTab) && isAllowed(userAccess, ['card_issuance_overview.view']) && summary.link && (
                          <button type="button" className="summary-link" onClick={summary.link?.linkAction}>
                            {summary.link.linkText}
                            <span className="icon-w">
                              <span className="os-icon os-icon-arrow-up-right" />
                            </span>
                          </button>
                        )}
                      </div>
                    ))}
                  </section>
                  {/** @dev Please leave this comment */}
                  {/* <section className="second-section">
              <div className="info-summary-item">
                <p className="label">
                  Total Security Reserve ({activeCurrency})
                  <span>
                    <ToolTip type="issuance-summary-security-reserve" image={InfoIcon} message="Some random stuff" />
                  </span>
                </p>
                <h3 className="value">1.03M</h3>
                <p className="description">Funds held temporarily in Reserve</p>
              </div>
            </section> */}
                </section>
              )}
              {activeTab !== 'overview' && (
                <section className="transaction_table_comp table-container">
                  <Table
                    header={null}
                    className={state.tableData?.className || ''}
                    tableHeadings={tableDataKeys}
                    loading={isFetching}
                    data={data?.data || []}
                    renderFields
                    hasPagination
                    annotation={state.tableData?.annotations}
                    rowKey={state.tableData?.rowKey}
                    rowURL={state.tableData?.rowURL}
                    pageSize={data?.paging?.page_size}
                    totalItems={data?.paging?.total_items}
                    emptyStateHeading={state.tableData?.emptyStateHeading || 'No records yet'}
                    tableWrapperClassName="table-responsive"
                    emptyStateMessage={
                      <>
                        <span>{state.tableData?.emptyStateMessage || ''}</span>
                        {computeViewPermissions() && (
                          <button type="button" className="refetch-button" onClick={() => refetch()}>
                            <i className="os-icon os-icon-rotate-ccw mr-1" />
                            Refresh
                          </button>
                        )}
                      </>
                    }
                    type="issuance-home"
                    filterType={state.tableData?.type || ''}
                    filterName={state.tableData?.filterTitle || ''}
                    filterHasAdvancedFilter={computeViewPermissions()}
                    filterKeywordPlaceholder={state.tableData?.filterKeywordPlaceholder || ''}
                    filterExportAction={exportRecords}
                    filterTotalCount={data?.paging?.total_items}
                    filterShowExport={computeExportPermissions()}
                    filterActiveCurrency={activeCurrency}
                  >
                    {state.tableData?.fields}
                  </Table>
                </section>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}

export default function IssuingModule() {
  const userAccess = useSetUserAccess();
  return (
    <div className="content-i">
      <div className="content-box issuing-module__comp">
        <Switch>
          <Route exact path="/dashboard/card-issuance">
            <Issuing />
          </Route>
          <Route path="/dashboard/card-issuance/issuedCards/:id">
            {isAllowed(userAccess, ['card_issuance_card_details.view']) ? (
              <IssuedCardDetails />
            ) : (
              <Redirect to="/dashboard/access-denied" />
            )}
          </Route>
          <Route path="/dashboard/card-issuance/reservedCards/:id">
            {isAllowed(userAccess, ['card_issuance_card_details.view']) ? (
              <IssuedCardDetails />
            ) : (
              <Redirect to="/dashboard/access-denied" />
            )}
          </Route>
          <Route path="/dashboard/card-issuance/chargebacks/:id">
            {isAllowed(userAccess, ['card_issuance_chargeback_details.view']) ? (
              <ChargebackTransactionDetails />
            ) : (
              <Redirect to="/dashboard/access-denied" />
            )}
          </Route>
          <Route path="/dashboard/card-issuance/merchants/:id">
            <IssuedMerchantDetails />
          </Route>
          <Route path="/dashboard/card-issuance/balanceFunding/:id">
            <BalanceFundingDetails />
          </Route>
          <Route path="/dashboard/card-issuance/merchants/:id">
            <IssuedMerchantDetails />
          </Route>
          <Route path="/dashboard/card-issuance/billing/:id">
            {isAllowed(userAccess, ['card_issuance_billing_details.view']) ? (
              <BillingDetails />
            ) : (
              <Redirect to="/dashboard/access-denied" />
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
}
