import { IStatusConfigObj, StatusConfigType } from '+types';

import discoverLogo from '+assets/img/logos/discover.png';
import bankIcon from '+assets/img/dashboard/bank-icon.svg';
import Wema from '+assets/img/logos/alat.svg';
import GTBank from '+assets/img/logos/gtbank.svg';
import Kudabank from '+assets/img/logos/kudabank.svg';
import maestroLogo from '+assets/img/logos/maestro.svg';
import mastercardLogo from '+assets/img/logos/mastercard.svg';
import verveLogo from '+assets/img/logos/verve.svg';
import visaLogo from '+assets/img/logos/visa.svg';
import Zenith from '+assets/img/logos/zenith.svg';

export const switchCurrency = {
  NGN: 'Naira (NGN)',
  USD: 'Dollar (USD)',
  EUR: 'Euro (EUR)',
  GBP: 'Pound (GBP)',
  KES: 'Kenyan Shilling (KES)',
  GHS: 'Ghanaian Cedi (GHS)',
  ZAR: 'South African Rand (ZAR)',
  XAF: 'Central African CFA Franc (XAF)',
  XOF: 'West African CFA Franc (XOF)',
  EGP: 'Egyptian Pound (EGP)'
} as const;

export const switchCurrencyWithoutSymbol = {
  NGN: 'Nigerian Naira',
  USD: 'US Dollar',
  EUR: 'European Euro',
  GBP: 'British Pound',
  KES: 'Kenyan Shilling',
  GHS: 'Ghanaian Cedi',
  ZAR: 'South African Rand',
  XAF: 'Central African CFA Franc',
  XOF: 'West African CFA Franc',
  EGP: 'Egyptian Pound'
} as const;

export const availableCurrency = {
  NGN: 'NGN',
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  KES: 'KES',
  GHS: 'GHS',
  ZAR: 'ZAR',
  XOF: 'XOF',
  XAF: 'XAF',
  EGP: 'EGP'
};

export const switchChannel = (channel: string): string => {
  switch (channel) {
    case 'api':
      return 'API';
    case 'modal':
      return 'Korapay Checkout';
    case 'web':
      return 'Korapay Dashboard';
    default:
      return 'Not Available';
  }
};

export const switchCard = {
  mastercard: mastercardLogo,
  verve: verveLogo,
  visa: visaLogo,
  maestro: maestroLogo,
  discover: discoverLogo
};

export const switchBank = {
  default: bankIcon,
  'kuda-mfb': Kudabank,
  zenith: Zenith,
  wema: Wema,
  gtbank: GTBank
};

export const keyToCustomTextMap = {
  pending: 'Pending',
  processing: 'Processing',
  accepted: 'Accepted',
  declined: 'Declined',
  invalid: 'Invalid',
  partially_accepted: 'Accepted (Partially)',
  pending_pre_arbitration: 'Pending (Pre-Arb)',
  processing_pre_arbitration: 'Processing (Pre-Arb)',
  delayed_pre_arbitration: 'Delayed (Pre-Arb)',
  declined_pre_arbitration: 'Declined (Pre-Arb)',
  invalid_pre_arbitration: 'Invalid (Pre-Arb)',
  accepted_pre_arbitration: 'Accepted (Pre-Arb)',
  partially_accepted_pre_arbitration: 'Accepted (Partially, Pre-Arb)',
  accepted_arbitration: 'Accepted (Arb)',
  declined_arbitration: 'Declined (Arb)'
};

export const switchTrxnMessage = {
  success: { name: `Transaction successful`, color: '#24B314', backgroundColor: '#e4fff1' },
  processing: { name: 'Transaction in progress...', color: '#FA9500', backgroundColor: '#FFF8E1' },
  requires_auth: { name: 'Transaction requires authorization', color: 'rgba(0, 0, 0, 0.5)', backgroundColor: '' },
  require_auth: { name: 'Transaction requires authorization', color: 'rgba(0, 0, 0, 0.5)', backgroundColor: '' },
  failed: { name: 'Transaction failed', color: '#F32345', backgroundColor: '#FFD2DA' },
  expired: { name: 'Transaction expired', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  rejected: { name: 'Transaction rejected', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  abandoned: { name: 'Transaction abandoned', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  won: { name: 'Won', color: '#24B314', backgroundColor: '#E4FFF1' },
  accepted: { name: 'Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  pending: { name: 'Pending', color: '#FA9500', backgroundColor: '#FFF8E1' },
  card_pending: { name: 'Pending', color: '#FA9500', backgroundColor: '#FFF8E1' },
  card_active: { name: 'Active', backgroundColor: '#E4FFF1', color: '#24B314' },
  lost: { name: 'Chargeback Lost', color: '#F32345', backgroundColor: '#FFD2DA' },
  declined: { name: 'Chargeback Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  partial: { name: 'Partial', color: '#24B314', backgroundColor: '#E4FFF1' },
  auto_accepted: { name: 'Accepted (Auto)', color: '#F32345', backgroundColor: '#FFD2DA' },
  enabled: { name: `Enabled`, color: '#24B314', backgroundColor: '#e4fff1' },
  disableds: { name: 'Disabled', color: '#414F5F', backgroundColor: '#F1F6FA' },
  disabled: { name: 'Disabled', color: '#F32345', backgroundColor: '#FFD2DA' },
  disabled_access: { name: 'Disabled', color: '#414F5F', backgroundColor: '#F1F6FA' },
  reversed: { name: 'Reversed', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  void_authorization: { name: 'Voided (Auth)', color: '#414F5F', backgroundColor: '#F1F6FA' },
  void_capture: { name: 'Voided (Capture)', color: '#414F5F', backgroundColor: '#F1F6FA' },
  pre_authorized: { name: 'Pre-Authorized', color: '#6474FF', backgroundColor: '#EBEDFF' },
  paused: { name: 'Transaction On Hold', color: '#414F5F', backgroundColor: '#F3F4F8' },
  flagged_success: { name: 'Flagged Success', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  released: {
    name: 'Lien released',
    backgroundColor: '#E4FFF1',
    color: '#24B314'
  },
  withheld: {
    name: 'Lien debited',
    backgroundColor: '#EBEDFF',
    color: '#6474FF'
  },
  active: {
    name: 'Lien placed',
    backgroundColor: '#FFD2DA',
    color: ' #F32345'
  },
  manual: { name: 'Manual', color: '#2cadff', backgroundColor: '#E6F3FF' }
} as const;

export const chargebackStatusTagConfig = {
  pending: { name: 'Chargeback Pending', color: '#FA9500', backgroundColor: '#FFF8E1' },
  processing: { name: 'Chargeback Processing', color: '#FA9500', backgroundColor: '#FFF8E1' },
  accepted: { name: 'Chargeback Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  partially_accepted: { name: 'Chargeback Accepted (Partially)', color: '#48CEB0', backgroundColor: '#E4FFF1' },
  invalid: { name: 'Chargeback Invalid', color: '#94A7B7', backgroundColor: '#F3F4F8' },
  declined: { name: 'Chargeback Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  pending_pre_arbitration: { name: 'Pre-arbitration Pending', color: '#FFB447', backgroundColor: '#FFF8E1' },
  processing_pre_arbitration: { name: 'Pre-arbitration Processing ', color: '#FA9500', backgroundColor: '#FFF8E1' },
  accepted_pre_arbitration: { name: 'Pre-arbitration Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  partially_accepted_pre_arbitration: { name: 'Pre-arbitration Accepted (Partially)', color: '#24B314', backgroundColor: '#E4FFF1' },
  delayed_pre_arbitration: { name: 'Pre-arbitration Delayed', color: '#F32345', backgroundColor: '#FFD2DA' },
  invalid_pre_arbitration: { name: 'Pre-arbitration Invalid', color: '#94A7B7', backgroundColor: '#F3F4F8' },
  declined_pre_arbitration: { name: 'Pre-arbitration Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  accepted_arbitration: { name: 'Arbitration Accepted', color: '#F32345', backgroundColor: '#FFD2DA' },
  declined_arbitration: { name: 'Arbitration Declined', color: '#F32345', backgroundColor: '#FFD2DA' }
};

export const balanceFundingConfig = {
  pending: { name: 'Pending', color: '#FA9500', backgroundColor: '#FFF8E1' },
  approved: { name: 'Approved', color: '#5053f7', backgroundColor: '#5053f721' },
  declined: { name: 'Declined', color: '#F32345', backgroundColor: '#FFD2DA' }
};

export const payinMethodMapping = {
  bank_transfer: 'Bank Transfer',
  wallet: 'Wallet Transfer',
  card: 'Card Payment',
  pay_with_bank: 'Pay with Bank (Direct debit)',
  mobile_money: 'Mobile Money'
};

export const payoutMethodMapping = {
  bank_account: 'Bank Transfer',
  wallet: 'Wallet Transfer',
  mobile_money: 'Mobile Money'
};

export const formatPAN = (value: string): string => {
  if (!value) return '';
  const separated = value.match(/.{1,4}/g) || [];
  return separated.join(' ');
};

export const chargebackActivityStatus: Record<string, string> = {
  won: '-- Chargeback won --',
  lost: '-- Chargeback lost --',
  partial: '-- Partial Chargeback --',
  accepted: '-- Chargeback accepted --',
  declined: '-- Chargeback declined --',
  pending: ''
};

export const switchCurrencyPair: Record<string, string> = {
  NGN: 'NGN-USD',
  USD: 'USD-NGN'
};

export const availableProductCategories = { 'pay-ins': 'Pay-ins', payouts: 'Pay-out', 'card-issuance': 'Card Issuance' } as const;

export const availableProducts = {
  bank_transfer: 'Bank Transfer',
  wallet: 'Wallet',
  card: 'Cards',
  pay_with_bank: 'Pay With Bank',
  bank_account: 'Bank Account',
  mobile_money: 'Mobile Money'
} as const;

export const productMapping = {
  'pay-ins': 'collection',
  payouts: 'disbursement',
  'card-issuance': 'card-issuance'
} as const;

export const switchStatusConfig = (type: StatusConfigType): Record<string, IStatusConfigObj> => {
  switch (type) {
    case 'chargebacks':
      return chargebackStatusTagConfig;
    case 'balance_funding':
      return balanceFundingConfig;
    case 'default':
    default:
      return switchTrxnMessage;
  }
};

export const statusPropsMap = {
  success: { name: 'Successful', color: '#24B314', icon: '✓' },
  pending: { name: 'Pending', color: 'rgba(16,38,73,.4)' },
  processing: { name: 'Processing', color: 'rgba(16,38,73,.4)' },
  failed: { name: 'Failed', color: '#F32345', icon: '✗' },
  partially_paid: { name: 'Partially Paid', color: '#24B314' },
  fully_paid: { name: 'Paid', color: '#24B314' },
  accepted: { name: 'Accepted', color: '#24b314' },
  partially_accepted: { name: 'Accepted (Partially)', color: '#48ceb0' },
  invalid: { name: 'Invalid', color: '#94a7b7' },
  declined: { name: 'Declined', color: '#f32345' },
  pending_pre_arbitration: { name: 'Pre-arbitration Pending', color: '#ffb447' },
  processing_pre_arbitration: { name: 'Pre-arbitration Processing ', color: '#fa9500' },
  accepted_pre_arbitration: { name: 'Pre-arbitration Accepted', color: '#24b314' },
  partially_accepted_pre_arbitration: { name: 'Pre-arbitration Accepted (Partially)', color: '#24b314' },
  delayed_pre_arbitration: { name: 'Pre-arbitration Delayed', color: '#f32345' },
  invalid_pre_arbitration: { name: 'Pre-arbitration Invalid', color: '#94a7b7' },
  declined_pre_arbitration: { name: 'Pre-arbitration Declined', color: '#f32345' },
  accepted_arbitration: { name: 'Arbitration Accepted', color: '#f32345' },
  declined_arbitration: { name: 'Arbitration Declined', color: '#f32345' },
  pending_approval: { name: 'Pending Approval', color: '#ffb447' },
  manual: { name: 'Manual', color: '#2cadff' }
};

export type Status = keyof typeof statusPropsMap;
