/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import classnames from 'classnames';
import qs from 'qs';

import backgroundImage from '+assets/theme/img/bg-merchant-details.jpg';
import TierIcon from '+containers/Dashboard/Shared/TierIcon';
import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useQueryStorage from '+hooks/useQueryStorage';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import Copyable from '+shared/Copyable';
import Icon from '+shared/Icons';
import RiskLevel from '+shared/RiskLevel';
import Scrollable from '+shared/Scrollable';
import ToolTip from '+shared/Tooltip';
import { IMerchantData } from '+types';
import { history, isAllowed, logError, storageDataKey, truncateString } from '+utils';

import AuditsTab from './components/AuditLogs';
import BalancesTab from './components/Balances/Balances';
import BankAccounts from './components/BankAccounts';
import GeneralTab from './components/BusinessInformation';
import Conversions from './components/Conversions';
import EmailConfiguration from './components/EmailConfiguration';
import FeesTab from './components/Fees';
import Limits from './components/Limits';
import MerchantSettlements from './components/MerchantSettlements';
import PaymentPreference from './components/PaymentPreference';
import RollingReserve from './components/RollingReserve';
import TeamsTab from './components/Teams/Teams';
import WhiteListedIPAddress from './components/WhiteListedIPs';

import companyZ from '+assets/theme/img/company-z.png';

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);
const tabs: string[] = [];

function MerchantDetails() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const { useKoraId } = qs.parse(location.search, { ignoreQueryPrefix: true }) as { useKoraId: string };
  const { feedbackInit } = useFeedbackHandler();
  const [data, setData] = useState({} as IMerchantData);
  const searchQuery = useSearchQuery<{ tab: string }>();
  const activeTab = searchQuery.value.tab ?? 'General';

  const { storeQueryData } = useQueryStorage();
  const { fetching_status: fetched } = data;

  const userAccess = useSetUserAccess();
  const rightRef = useRef<boolean>();

  useEffect(() => {
    rightRef.current = !!isAllowed(userAccess, ['merchant_settlement_config.view', 'merchant_settlement_config.update']);
  }, [userAccess]);

  const { data: sentinalStatus } = useQuery([`MERCHANT_SENTINEL_STATUS_${id}`], () => apiRequest.manageSentinal(id), {
    onError: (err: AxiosError) => {
      if (err?.response?.status === 404) return;
      logError(err);
      feedbackInit({
        message: "There has been an error getting  this merchant's sentinal status. Please try again.",
        type: 'danger'
      });
    }
  });

  const getMerchantSettlementsMutation = useMutation((id: string) => apiRequest.getMerchantSettlements(id), {
    onError: (error: AxiosError) => {
      logError(error);
      feedbackInit({
        message: 'There has been an error fetching merchant settlements, Please try again',
        type: 'danger'
      });
    }
  });

  const getMerchantSettlements = (id: string) => {
    if (rightRef.current) {
      return getMerchantSettlementsMutation.mutateAsync(id);
    }
    return {};
  };

  const singleMerchantMutation = useMutation(
    (variable: { id: string }) => {
      if (useKoraId) {
        return apiRequest.getMerchantByKoraId(variable.id);
      }
      return apiRequest.getMerchant(variable.id);
    },
    {
      onError: (error: AxiosError) => {
        logError(error);
        feedbackInit({
          message: `There has been an error getting this merchant's details`,
          type: 'danger'
        });
      }
    }
  );

  const singleMerchant = (variable: string) => {
    return singleMerchantMutation.mutateAsync({ id: variable });
  };

  useEffect(() => {
    let merchantData: IMerchantData;
    let merchantSettlements;
    let merchant;

    singleMerchant(id)
      .then(async result => {
        merchantData = result;

        if (useKoraId) {
          history.replace(`/dashboard/merchant/${result?.id}`);
          return;
        }

        merchantSettlements = await getMerchantSettlements(result?.id);
        merchant = { ...merchantData, fetching_status: 'fetched', settlements: merchantSettlements?.data };

        storeQueryData(storageDataKey.SINGLE_MERCHANT, merchant);
        Storage.setItem(storageDataKey.SINGLE_MERCHANT, JSON.stringify(merchant));
        setData(merchant);
      })
      .catch(err => {
        logError(err);
        feedbackInit({
          message: merchantData
            ? `There has been an error getting this merchant's settlements`
            : `There has been an error getting this merchant's details`,
          type: 'danger'
        });
        merchant = { ...data, ...merchantData, fetching_status: merchantData ? 'success' : 'failed' };
        storeQueryData(storageDataKey.SINGLE_MERCHANT, merchant);
        setData(merchant);
      });
  }, [id]);

  const [state, setState] = useState({
    currency: 'NGN',
    isActive: true,
    tabs
  });

  useLayoutEffect(() => {
    if (isAllowed(userAccess, ['merchant_general_details.view'])) {
      tabs.push('General');
    }

    if (isAllowed(userAccess, ['merchant_balances.view'])) {
      tabs.push('Balances');
    }
    if (isAllowed(userAccess, ['merchant_rolling_reserve_config.view'])) {
      tabs.push('Rolling Reserve');
    }
    if (isAllowed(userAccess, ['merchant_settlement_account.view'])) {
      tabs.push('Bank Accounts');
    }
    if (isAllowed(userAccess, ['merchant_settlement_config.view', 'merchant_settlement_config.update'])) {
      tabs.push('Settlements');
    }
    tabs.push('Conversions');
    if (isAllowed(userAccess, ['merchant_fee_config.view', 'merchant_fee_config.create', 'merchant_fee_config.update'])) {
      tabs.push('Fees');
    }
    if (isAllowed(userAccess, ['merchant_teams.view'])) {
      tabs.push('Teams');
    }
    if (isAllowed(userAccess, ['merchant_audit_logs.view'])) {
      tabs.push('Audit Logs');
    }
    if (isAllowed(userAccess, ['bank_transfer_settings_requests.view'])) {
      tabs.push('Payment Preferences');
    }
    if (isAllowed(userAccess, ['merchant_compliance.view'])) {
      tabs.push('Limits');
    }
    if (isAllowed(userAccess, ['ip_whitelisted_address.view'])) {
      tabs.push('Whitelisted IPs');
    }
    tabs.push('Notifications');
    setState(prev => ({ ...prev, activeTab: tabs[0] }));
    return () => {
      tabs.splice(0, tabs.length);
    };
  }, [userAccess]);

  const switchTabs = (): JSX.Element => {
    switch (activeTab) {
      case 'Balances':
        return <BalancesTab />;
      case 'Bank Accounts':
        return <BankAccounts />;
      case 'Audit Logs':
        return <AuditsTab />;
      case 'Rolling Reserve':
        return <RollingReserve />;
      case 'Fees':
        return <FeesTab />;
      case 'Teams':
        return <TeamsTab />;
      case 'Settlements':
        return <MerchantSettlements />;
      case 'Payment Preferences':
        return <PaymentPreference />;
      case 'Notifications':
        return <EmailConfiguration />;
      case 'Limits':
        return <Limits />;
      case 'Whitelisted IPs':
        return <WhiteListedIPAddress />;
      case 'Conversions':
        return <Conversions />;
      default:
        return <GeneralTab sentinalData={sentinalStatus} />;
    }
  };
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back to Merchants</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="element-box-tp">
              <div className="user-profile">
                <div className="up-head-w" style={{ backgroundImage: `url(${backgroundImage}` }}>
                  <section className="info-container">
                    <div className="up-main-info">
                      <div className="user-avatar-w">
                        <div className="user-avatar">
                          <img alt="" src={companyZ} />
                        </div>
                      </div>
                      <div className="up-heading">
                        <h1 id="merchant-name" className="up-header light">
                          <span>{data.name}</span>
                          {sentinalStatus && !sentinalStatus.enabled && <span className="sen-disabled">Sen Disabled</span>}
                        </h1>
                        <h5 className="up-sub-header light">
                          ID: {id}{' '}
                          {sentinalStatus && (
                            <span className="sen-id">
                              | Sen ID: <Copyable text={sentinalStatus.identifier} textModifier={text => truncateString(text, 5)} />
                            </span>
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="value-pair align-items-center">
                      <div className="value-pair align-items-center tier-value-pair mr-0 ">
                        {data?.tier_level?.name?.toLowerCase() === 'none' ||
                          (data?.tier_level?.name && (
                            <TierIcon id={Number(data?.tier_level?.name?.slice?.(-1))} roundedBorderColor="#1C3B6F" variant="yellow" />
                          ))}
                        <div className="d-flex align-items-center">
                          <p className="my-0 ml-2">{data?.tier_level?.name}</p>
                          {data?.tier_level?.description && (
                            <ToolTip type="tier info" message={<p className="tier-tooltip-text">{data?.tier_level?.description}</p>}>
                              <Icon name="infoQuestionMark" width={11} height={11} style={{ marginTop: '-6px' }} />
                            </ToolTip>
                          )}
                        </div>
                      </div>
                      <span className="risk-level--divider" />
                      {data?.risk_level && <RiskLevel riskLevel={data.risk_level} />}
                      {isAllowed(userAccess, ['merchant_status.update']) && (
                        <button
                          type="button"
                          className="activation-button manage"
                          disabled={!['deactivated', 'active'].includes(data.status)}
                          onClick={() => history.push(`/dashboard/kyc-approval/${id}`)}
                        >
                          Manage Merchant
                        </button>
                      )}
                    </div>
                  </section>
                  {(data?.kyc_status === 'unverified' || data?.status === 'unsupported') && (
                    <section className="status-banner __unverified">
                      <Icon name="warningOrange" />{' '}
                      {data?.status === 'unsupported'
                        ? 'This business signed up from an unsupported country!'
                        : 'This business account has not yet been verified!'}
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3 mb-5">
            <div className="os-tabs-w">
              <div className="os-tabs-controls os-tabs-complex">
                <ul className="nav nav-tabs">
                  <Scrollable>
                    {state.tabs.map(tab => {
                      return (
                        <li className="nav-item" key={tab}>
                          <button
                            type="button"
                            onClick={() => searchQuery.setQuery({ tab }, true)}
                            className={classnames('nav-link details--custom--button', { active: activeTab === tab })}
                          >
                            <span className="tab-label">{tab}</span>
                          </button>
                        </li>
                      );
                    })}
                  </Scrollable>
                </ul>
              </div>
              {fetched === 'fetched' && switchTabs()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantDetails;
