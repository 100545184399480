import React, { Dispatch, memo, SetStateAction } from 'react';
import { useFormik } from 'formik';

import { advanceCleanInput } from '+utils';

const ManageAccForm = ({
  showDescription = false,
  options,
  headerText,
  contentText,
  setReasonText,
  setReason
}: {
  showDescription?: boolean;
  options?: { label: string; value: string }[];
  headerText?: string;
  contentText?: string;
  setReasonText: Dispatch<SetStateAction<string>>;
  setReason: Dispatch<SetStateAction<string>>;
}) => {
  const form = useFormik({
    initialValues: {
      reason: '',
      reasonText: ''
    },
    onSubmit: () => undefined
  });
  return (
    <>
      {options && (
        <div className="form-group">
          <label htmlFor="reason">
            <span className="dark">{headerText || 'Select Options'}</span>
          </label>
          <select
            name="reason"
            data-testid="select-reason"
            className="form-control"
            value={form.values.reason}
            onChange={e => {
              const { value } = e.target;
              form.setFieldValue('reason', value);
              setReason(value);
              setReasonText('');
            }}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}

      {(form.values.reason === 'other' || showDescription) && (
        <div className="form-group">
          <label htmlFor="reason-text">
            <span className="dark">{contentText ?? 'Please tell us why'}</span>
          </label>
          <textarea
            rows={3}
            maxLength={100}
            name="reason-text"
            className="form-control"
            placeholder=""
            value={form.values.reasonText}
            onChange={e => {
              form.setFieldValue('reasonText', advanceCleanInput(e.target.value));
              setReasonText(advanceCleanInput(e.target.value));
            }}
            data-testid="reason-text"
          />
        </div>
      )}
    </>
  );
};

export default memo(ManageAccForm);
