import React from 'react';
import APIRequest from '+services/api-services';
import { ITableData, Nullable } from '+types';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, keyToCustomTextMap, switchStatus } from '+utils';

import mastercard from '+assets/img/logos/mastercard.svg';
import visa from '+assets/img/logos/visa.svg';
import BusinessAvatar from '+assets/theme/img/biz-avatar.png';

const api = new APIRequest();

export type ReceiptPropsTypeType = {
  currency: string;
  amount: number;
  reference: string;
  merchant_name: string;
  status: string;
  type: string;
  created_at: string;
};

type summaryElement = {
  label: string;
  value: number | string;
  description: string | React.ReactNode;
  tooltip?: string;
  link?: {
    linkText: string;
    linkAction: () => void;
  };
};

type TabKeyType =
  | 'overview'
  | 'card_transactions'
  | 'issued_cards'
  | 'reserved_cards'
  | 'card_disputes'
  | 'issuing_merchants'
  | 'approvals'
  | 'billing';

type SubTabKeyType = 'issuance_chargebacks' | 'balance_funding' | 'billing_history' | 'manual_debit';

export const queryKeys = {
  ISSUING_STATS: 'ISSUING_STATS',
  ISSUING_TRANSACTIONS: 'ISSUING_TRANSACTIONS',
  ISSUED_CARDS: 'ISSUED_CARDS',
  CARD_DISPUTES: 'CARD_DISPUTES',
  APPROVALS: 'APPROVALS',
  RESERVED_CARDS: 'RESERVED_CARDS',
  ISSUING_MERCHANTS: 'ISSUING_MERCHANTS',
  BILLING: 'BILLING'
} as const;

export type queryKeyType = keyof typeof queryKeys;

interface SubTabType {
  key: SubTabKeyType;
  label: string;
  hasCounter?: boolean;
  showCounter?: boolean;
  query: <T>(...args: any[]) => Promise<T>;
  queryKey: queryKeyType;
  queryStatus?: string;
}

type TabListType = Array<{
  key: TabKeyType;
  label: string;
  hasCounter?: boolean;
  showCounter?: boolean;
  description?: string | JSX.Element;
  query?: <T>(...args: any[]) => Promise<T>;
  queryKey?: queryKeyType;
  counterKey?: queryKeyType;
  subTabs?: Array<SubTabType>;
}>;

export const switchTabData = (activeTab: string, state?: any, paging?: any): Nullable<ITableData | string> => {
  function getTableTitle(title: string, def = true) {
    const filtered: boolean = Object.keys(state?.sortingParams || {}).length > 0;
    return def
      ? `${filtered ? 'Filtered' : 'All'} ${title} (${formatAmount(paging?.total_items || 0, false)})`
      : `${formatAmount(paging?.total_items || 0, false)} ${title} ${filtered ? '(Filtered)' : ''}`;
  }

  type eventTypes = 'creation' | 'deactivation' | 'suspension' | 'funding' | 'activation';
  const getExplanation = (event: { event: string; creator: string }): string => {
    const verbOptions = {
      creation: 'created',
      termination: 'terminated',
      activation: 'reactivated',
      deactivation: 'deactivated',
      suspension: 'suspended',
      funding: 'funded'
    };
    const verb = verbOptions[event?.event?.toLowerCase() as eventTypes];
    return `: ${event.creator} ${verb} this virtual card`;
  };

  const isDebitFromEvent = (text: string) => ['debit', 'card_transaction'].includes(text);

  switch (activeTab) {
    case 'issuing_merchants':
      return {
        type: 'issuing-merchants',
        filterTitle: getTableTitle('Issuing merchants', true),
        className: 'issuing-merchants',
        rowURL: '/dashboard/card-issuance/merchants',
        rowKey: 'reference',
        emptyStateHeading: 'No merchants found',
        emptyStateMessage: 'It seems there are no issuing merchants cards yet.',
        annotations: 'merchant(s)',
        fields: (iter: any) => ({
          data: {
            merchant: (
              <>
                <img alt="business avatar" src={BusinessAvatar} />
                <span className="merchant-name" data-color="blue">
                  {iter.name}
                </span>
              </>
            ),
            issuing_status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            subscription_plan: iter.plan || '--',
            date_and_time_added: (
              <>
                <span data-weight="500">{getDate(iter.date_created)},</span>&nbsp;<span>{getTime(iter.date_created)}</span>
              </>
            )
          }
        })
      };
    case 'issued_cards':
      return {
        type: 'issued-cards',
        filterTitle: getTableTitle('issued cards'),
        className: 'issued-cards',
        rowURL: '/dashboard/card-issuance/issuedCards',
        rowKey: 'reference',
        emptyStateHeading: 'No cards found',
        emptyStateMessage: 'It seems there are no records of issued cards here.',
        annotations: 'card(s)',
        fields: (iter: any) => ({
          data: {
            card_status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            unique_card_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            issuing_merchant: iter.merchant_name,
            cardholder: iter.holder_name,
            card_number: (
              <span className="card-container">
                <img
                  className="card-scheme"
                  alt={iter.brand}
                  src={iter.brand === 'visa' ? visa : mastercard}
                  style={{ width: '22px', marginRight: '7px' }}
                />
                <span className="card-digits">**** {iter.last_four}</span>
              </span>
            )
          }
        })
      };
    case 'merchant_customer_cards':
      return {
        type: 'issued-cards',
        filterTitle: getTableTitle('issued cards'),
        className: 'issued-cards',
        rowURL: '/dashboard/card-issuance/issuedCards',
        rowKey: 'reference',
        emptyStateHeading: 'No cards found',
        emptyStateMessage: 'It seems there are no records of issued cards here.',
        annotations: 'card(s)',
        fields: (iter: any) => ({
          data: {
            card_status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            unique_card_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            cardholder: iter.holder_name,
            created_at: (
              <>
                <span data-weight="500">{getDate(iter.date_created)},</span> <span>{getTime(iter.date_created)}</span>
              </>
            ),
            card_number: (
              <span className="card-container">
                <img
                  className="card-scheme"
                  alt={iter.brand}
                  src={iter.brand === 'visa' ? visa : mastercard}
                  style={{ width: '22px', marginRight: '7px' }}
                />
                <span className="card-digits">**** {iter.last_four}</span>
              </span>
            )
          }
        })
      };
    case 'merchant_reserved_cards':
      return {
        type: 'issued-cards',
        filterTitle: getTableTitle('issued cards'),
        className: 'merchant-reserved-cards',
        rowURL: '/dashboard/card-issuance/issuedCards',
        rowKey: 'reference',
        emptyStateHeading: 'No cards found',
        emptyStateMessage: 'It seems there are no records of issued cards here.',
        annotations: 'card(s)',
        fields: (iter: any) => ({
          data: {
            card_status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            unique_card_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            card_label: iter.label,
            created_at: (
              <>
                <span data-weight="500">{getDate(iter.date_created)},</span> <span>{getTime(iter.date_created)}</span>
              </>
            ),
            card_number: (
              <span className="card-container">
                <img
                  className="card-scheme"
                  alt={iter.brand}
                  src={iter.brand === 'visa' ? visa : mastercard}
                  style={{ width: '22px', marginRight: '7px' }}
                />
                <span className="card-digits">**** {iter.last_four}</span>
              </span>
            )
          }
        })
      };
    case 'reserved_cards':
      return {
        type: 'issued-cards',
        filterTitle: getTableTitle('reserved cards'),
        className: 'issued-cards',
        rowURL: '/dashboard/card-issuance/reservedCards',
        rowKey: 'reference',
        emptyStateHeading: 'No cards found',
        emptyStateMessage: 'It seems there are no records of issued cards here.',
        fields: (iter: any) => ({
          data: {
            card_status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            unique_card_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            merchant: iter.merchant_name,
            card_label: iter.label,
            card_number: (
              <span className="card-container">
                <img
                  className="card-scheme"
                  alt={iter.brand}
                  src={iter.brand === 'visa' ? visa : mastercard}
                  style={{ width: '22px', marginRight: '7px' }}
                />
                <span className="card-digits">**** {iter.last_four}</span>
              </span>
            )
          }
        })
      };
    case 'card_transactions':
      return {
        type: 'cards-transactions',
        filterTitle: getTableTitle('card transactions'),
        className: 'cards-transactions',
        rowURL: '/dashboard/pay-ins/virtual-card',
        rowKey: 'reference',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions here.',
        annotations: 'transaction(s)',
        fields: (iter: any) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            transaction_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            type: `${capitalizeRemovedash(iter.type)}`,
            cardholder: iter.card_holder_name,
            'Date / Time': (
              <>
                <span data-weight="500">{getDate(iter.date)},</span>&nbsp;<span>{getTime(iter.date)}</span>
              </>
            ),
            amount: (
              <>
                <span className="semibold">{formatAmount(iter.amount)}</span>
                <span className="grey-text ml-1">{iter.currency}</span>
              </>
            )
          }
        })
      };
    case 'merchant_card_transactions':
      return {
        type: 'cards-transactions',
        filterTitle: getTableTitle('card transactions'),
        className: 'cards-transactions',
        rowURL: '/dashboard/pay-ins/virtual-card',
        rowKey: 'reference',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions here.',
        annotations: 'transaction(s)',
        fields: (iter: any) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            transaction_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            type: `${capitalizeRemovedash(iter.type)}`,
            cardholder: iter.card_holder_name,
            'Date / Time': (
              <>
                <span data-weight="500">{getDate(iter.date)},</span> <span>{getTime(iter.date)}</span>
              </>
            ),
            amount: (
              <>
                <span className="semibold">{formatAmount(iter.amount)}</span>
                <span className="grey-text ml-1">{iter.currency}</span>
              </>
            )
          }
        })
      };
    case 'chargebacks':
      return {
        filterTitle: getTableTitle('Chargebacks'),
        className: 'chargeback',
        emptyStateHeading: 'No chargeback found',
        emptyStateMessage: 'It seems there are no chargebacks here.',
        annotations: 'chargeback(s)',
        fields: (iter: any) => ({
          style: { backgroundColor: !iter.resolved ? '#FFF8E1' : '' },
          data: {
            status: (
              <>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(iter.cardStatus)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            chargeback_id: (
              <>
                Chargeback for{' '}
                <span
                  style={{
                    color: '#007bff',
                    fontWeight: '500',
                    textTransform: 'uppercase'
                  }}
                >
                  {iter.reference}
                </span>
              </>
            ),
            vendor: iter.vendorName,
            date_raised: `${getDate(iter.date)} ${getTime(iter.date)}`,
            'Resolved?': iter.resolved ? 'Yes' : 'No',
            chargeback_amount: (
              <>
                <span style={{ fontWeight: '500' }}>{formatAmount(iter.amount)}</span>
                <span style={{ marginLeft: '5px', display: 'inline' }}>{iter.currency}</span>
              </>
            )
          }
        })
      };
    case 'events':
      return {
        type: 'card-events',
        className: 'balance-history events',
        emptyStateHeading: 'No event found',
        emptyStateMessage: 'It seems there are no events here.',
        filterTitle: getTableTitle('events'),
        hasBasicFilter: false,
        filterHasAdvancedFilter: false,
        fields: (iter: any) => ({
          data: {
            date: (
              <span className="grey-text">
                {getDate(iter.date)} {getTime(iter.date)}
              </span>
            ),
            event: (
              <>
                <strong>{capitalize(iter.event)}</strong>
                {getExplanation(iter)}
              </>
            ),
            event_id: <span className="grey-text">{iter.reference}</span>,
            description: iter.reason ? capitalize(iter.reason) : '-'
          }
        })
      };
    case 'transactions':
      return {
        type: 'card-details-transactions',
        filterTitle: getTableTitle('card transactions'),
        className: '--card-detail-trxn',
        rowURL: '/dashboard/pay-ins/virtual-card',
        rowKey: 'reference',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions for this card yet.',
        filterHasAdvancedFilter: true,
        annotations: 'transaction(s)',
        fields: (iter: any) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            transaction_id: <span className="trxn-id">{iter.reference}</span>,
            type: `${capitalizeRemovedash(iter.type)}`,
            'Date / Time': (
              <span className="grey-text">
                {getDate(iter.date)} {getTime(iter.date)}
              </span>
            ),
            amount: (
              <>
                <span className="semibold">{formatAmount(iter.amount)}</span>
                <span className="annotation">{iter.currency}</span>
              </>
            )
          }
        })
      };
    case 'balance_history':
      return {
        type: 'balance-history',
        filterTitle: getTableTitle('History'),
        hasBasicFilter: false,
        filterHasAdvancedFilter: false,
        className: 'balance-history',
        emptyStateHeading: 'No history found',
        emptyStateMessage: 'It seems there has been no activity on this card yet.',
        annotations: 'entries',
        fields: (iter: any) => ({
          data: {
            date: (
              <span className="grey-text">
                {getDate(iter.date)} {getTime(iter.date)}
              </span>
            ),
            details: (
              <>
                {capitalizeRemovedash(iter.type)} - <span className="trxn-id">{iter.reference}</span>
              </>
            ),
            [`Amount (${iter.currency})`]: (
              <span
                className="semibold"
                style={{
                  color: !isDebitFromEvent(iter.type) ? '#24b314' : '#f32345'
                }}
              >
                {(!isDebitFromEvent(iter.type) ? '+ ' : '- ') + iter.amount}
              </span>
            ),
            [`Balance After (${iter.currency})`]: iter.balance_after || '-'
          }
        })
      };
    case 'issuing_history':
      return {
        filterTitle: getTableTitle('transactions'),
        className: 'balance-history',
        emptyStateHeading: 'No history found',
        emptyStateMessage: 'It seems there has been no activity on this card yet.',
        annotations: '',
        type: 'issuing_history',
        fields: (iter: any) => ({
          data: {
            date: (
              <>
                <span style={{ fontWeight: 500 }}>{getDate(iter.history_date)},</span>&nbsp;
                <span className="grey-text">{getTime(iter.history_date)}</span>
              </>
            ),
            details: (
              <>
                {capitalizeRemovedash(iter.description)} -&nbsp;
                <span className="trxn-id">{iter.reference}</span>
              </>
            ),
            amount: (
              <span
                style={{
                  color: iter.direction === 'credit' ? '#24b314' : '#f32345'
                }}
              >
                {(iter.direction === 'credit' ? '+ ' : '- ') + iter.amount}
              </span>
            ),
            balance_after: (
              <>
                <span style={{ fontWeight: 500 }}>{iter.balance_after}</span>&nbsp;{iter.currency}
              </>
            )
          }
        })
      };
    case 'security_resv._history':
      return {
        filterTitle: getTableTitle('History'),
        className: 'balance-history',
        emptyStateHeading: 'No history found',
        emptyStateMessage: 'It seems there has been no activity on this card yet.',
        annotations: 'entries',
        fields: (iter: any) => ({
          date: (
            <span className="grey-text">
              {getDate(iter.date)} {getTime(iter.date)}
            </span>
          ),
          details: (
            <>
              {capitalizeRemovedash(iter.type)} -{' '}
              <span className="trxn-id" onClick={() => history.push(`/dashboard/pay-ins/virtual-card/${iter.reference}`)}>
                {iter.reference}
              </span>
            </>
          ),
          [`Amount (${iter.currency})`]: (
            <span
              style={{
                color: iter.historyType === 'credit' ? '#24b314' : '#f32345'
              }}
            >
              {(iter.historyType === 'credit' ? '+ ' : '- ') + iter.amount}
            </span>
          ),
          [`Balance After (${iter.currency})`]: iter.balance_after
        })
      };
    case 'billing_history':
      return {
        type: 'billing_history',
        header: 'Card Issuance',
        filterTitle: getTableTitle('billing history'),
        className: '--billing-history',
        rowURL: '/dashboard/card-issuance/billing',
        rowKey: 'reference',
        filterKeywordPlaceholder: 'Search invoice',
        emptyStateHeading: 'No history found',
        emptyStateMessage: 'It seems there are no billing invoices yet.',
        annotations: 'invoice(s)',
        fields: (iter: any) => ({
          style: { background: iter.status === 'due' ? '#FFFAF0' : '' },
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status === 'pending' ? '' : iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            invoice_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            merchant: <span>{iter.merchant_name}</span>,
            fee_type: capitalizeRemovedash(iter.type),
            date_and_time: (
              <>
                <span>{getDate(iter.created_at)}</span>
                <span className="annotation">{getTime(iter.created_at)}</span>
              </>
            ),
            amount: (
              <>
                <span style={{ fontWeight: '500' }}>{formatAmount(iter.amount)}</span>
                <span style={{ marginLeft: '5px', display: 'inline' }}>{iter.currency}</span>
              </>
            )
          }
        })
      };
    case 'issuance_chargebacks':
      return {
        type: 'issuance-chargebacks',
        filterTitle: getTableTitle('card chargebacks'),
        className: 'issuance-chargebacks',
        rowURL: '/dashboard/card-issuance/chargebacks',
        rowKey: 'reference',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions here.',
        annotations: 'transaction(s)',
        fields: (iter: any) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{keyToCustomTextMap[iter.status as keyof typeof keyToCustomTextMap] || 'N/A'}</span>
              </>
            ),
            chargeback_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            merchant_name: iter.merchant_name,
            cardholder: iter.card_holder_name,
            date_and_time_escalated: (
              <>
                <span data-weight="500">{getDate(iter.escalation_date)},</span>&nbsp;<span>{getTime(iter.escalation_date)}</span>
              </>
            ),
            chargeback_amount: (
              <>
                <span className="semibold">{formatAmount(iter.amount)}</span>
                <span className="grey-text ml-1">USD</span>
              </>
            )
          },
          style: {
            backgroundColor: ['pending', 'pending_pre_arbitration', 'processing', 'processing_pre_arbitration'].includes(iter.status)
              ? '#FFF7ED'
              : null
          }
        })
      };
    case 'merchant_issuance_chargebacks':
      return {
        type: 'issuance-chargebacks',
        filterTitle: getTableTitle('card chargebacks'),
        className: 'issuance-chargebacks',
        rowURL: '/dashboard/card-issuance/chargebacks',
        rowKey: 'reference',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions here.',
        annotations: 'transaction(s)',
        fields: (iter: any) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{keyToCustomTextMap[iter.status as keyof typeof keyToCustomTextMap] || 'N/A'}</span>
              </>
            ),
            chargeback_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            cardholder: iter.card_holder_name,
            date_and_time_escalated: (
              <>
                <span data-weight="500">{getDate(iter.escalation_date)},</span> <span>{getTime(iter.escalation_date)}</span>
              </>
            ),
            chargeback_amount: (
              <>
                <span className="semibold">{formatAmount(iter.amount)}</span>
                <span className="grey-text ml-1">USD</span>
              </>
            )
          },
          style: {
            backgroundColor: ['pending', 'pending_pre_arbitration', 'processing', 'processing_pre_arbitration'].includes(iter.status)
              ? '#FFF7ED'
              : null
          }
        })
      };
    case 'balance_funding':
      return {
        type: 'balance_funding',
        filterTitle: getTableTitle('funding requests', true),
        className: 'balance-funding',
        rowURL: '/dashboard/card-issuance/balanceFunding',
        rowKey: 'reference',
        filterKeywordPlaceholder: 'Search details',
        emptyStateHeading: 'No transactions found',
        emptyStateMessage: 'It seems there are no transactions here.',
        annotations: 'transaction(s)',
        fields: (iter: any) => ({
          data: {
            status: (
              <>
                <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
                <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
              </>
            ),
            merchant: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'capitalize'
                }}
              >
                {iter.merchant}
              </span>
            ),
            transaction_id: (
              <span
                style={{
                  color: '#007bff',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}
              >
                {iter.reference}
              </span>
            ),
            date_and_time: <span className="grey-text">{`${getDate(iter.transaction_date)} ${getTime(iter.transaction_date)}`}</span>,
            amount: (
              <>
                <span className="semibold">{formatAmount(iter.amount)}</span>
                <span className="grey-text ml-1">USD</span>
              </>
            )
          }
        })
      };
    default:
      return null;
  }
};

export const IssuanceTabList: TabListType = [
  {
    key: 'overview',
    label: 'Overview',
    description:
      'Kora allows merchants to create, manage and issue payment cards for customers. Here’s an overview of the card issuance service for different merchants on Kora.',
    query: api.fetchStatistics,
    queryKey: queryKeys.ISSUING_STATS
  },
  {
    label: 'Issuing Merchants',
    key: 'issuing_merchants',
    description: 'These are merchants that have access to the Issuing service on Kora.',
    query: api.fetchAllIssuanceMerchants,
    queryKey: queryKeys.ISSUING_MERCHANTS
  },
  {
    label: 'Issued Cards',
    description: 'All cards issued by merchants are displayed here.',
    key: 'issued_cards',
    query: api.fetchAllIssuedCards,
    queryKey: queryKeys.ISSUED_CARDS
  },
  {
    label: 'Reserved Cards',
    description: 'All cards reserved for merchants are displayed here',
    key: 'reserved_cards',
    query: api.fetchAllReservedCards,
    queryKey: queryKeys.RESERVED_CARDS
  },
  {
    label: 'Card Transactions',
    key: 'card_transactions',
    description: 'These are all the transactions performed on issued cards.',
    query: api.fetchAllVirtualCardTransactions,
    queryKey: queryKeys.ISSUING_TRANSACTIONS
  },
  {
    label: 'Disputes',
    key: 'card_disputes',
    description: 'These are all the disputes on transactions performed on issued cards',
    subTabs: [
      {
        label: 'Chargebacks',
        key: 'issuance_chargebacks',
        query: api.fetchAllIssuanceChargebacks,
        queryKey: queryKeys.CARD_DISPUTES
      }
    ]
  },
  {
    label: 'Approvals',
    key: 'approvals',
    description: 'These are merchants’ requests that require admin authorisation.',
    showCounter: true,
    counterKey: queryKeys.APPROVALS,
    subTabs: [
      {
        label: 'Balance Funding',
        key: 'balance_funding',
        query: api.fetchBalanceFunding,
        queryStatus: 'pending',
        hasCounter: true,
        queryKey: queryKeys.APPROVALS
      }
    ]
  },
  {
    label: 'Billing',
    key: 'billing',
    description: 'These are all the billing invoices issued to merchants.',
    subTabs: [
      {
        label: 'Billing History',
        key: 'billing_history',
        query: api.fetchBillingHistory,
        queryKey: queryKeys.BILLING
      }
    ]
  }
];

/** @dev Please leave the comments here */
export const summaryInfo = (data: any, state: Record<string, any>, setTab: React.Dispatch<string>): summaryElement[] => [
  // {
  //   label: `Kora Issuing Balance (${state?.activeCurrency})`,
  //   value: '821.73M',
  //   description: 'For card issuing and transactions',
  //   tooltip: "Kora's issuing balance with our processor"
  // },
  // {
  //   label: `Merchant Issuing Balance (${state?.activeCurrency})`,
  //   value: '14.85M',
  //   description: 'Total balance for merchants to issue cards',
  //   link: {
  //     linkText: 'See Merchants',
  //     linkAction: () => setTab('issuing_merchants')
  //   }
  // },
  // {
  //   label: `Issued Cards Balance (${state.activeCurrency})`,
  //   value: '2.50M',
  //   description: 'Balance across all issued cards',
  //   link: {
  //     linkText: 'See Issued Cards',
  //     linkAction: () => setTab('issued_cards')
  //   }
  // },
  {
    label: 'Issuing Merchants',
    value: data?.count,
    description: 'Merchants with access to Issuing Service',
    link: {
      linkText: 'See Merchants',
      linkAction: () => setTab('issuing_merchants')
    }
  },
  {
    label: `Issued Cards`,
    value: data?.total_cards as number,
    description: 'Total cards issued by merchants',
    link: {
      linkText: 'See Issued Cards',
      linkAction: () => setTab('issued_cards')
    }
  },
  {
    label: `Active Issued Cards`,
    value: data?.total_active_cards as number,
    description: 'Total active cards issued by merchants',
    link: {
      linkText: 'See Issued Cards',
      linkAction: () => setTab('issued_cards')
    }
  },
  {
    label: `Card Transaction Count`,
    value: data?.total_transactions as number,
    description: 'Total volume of successful transactions (per month)',
    link: {
      linkText: 'See Card Transactions',
      linkAction: () => setTab('card_transactions')
    }
  },
  {
    label: `Card Transaction Value (${state?.activeCurrency})`,
    value: data?.total_transactions_value as number,
    description: 'Total value of successful transactions (per month)',
    link: {
      linkText: 'See Card Transactions',
      linkAction: () => setTab('card_transactions')
    }
  }
];

export const switchInfoDescription = {
  suspended:
    'A suspended card has a locked balance and cannot be funded or used to make payments. To reactivate a suspended card, click ‘Manage Card’ at the top-right corner of the card page',
  expired:
    'Once the expiry date of an issued card is reached, it can no longer be funded or used to make payments. You would need to issue a new card to the cardholder.',
  terminated:
    'A terminated card can no longer be funded or used to make payments. Also, funds in the balance of the terminated card are returned to the Issuing Balance.'
};
