import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { useFeedbackHandler, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import { IPayOutDetails, TTransactionDetails } from '+types';
import { formatAmount, history, isAllowed, isObjectNotEmpty, logError, switchTrxnMessage } from '+utils';

import FailedPayoutReasons from '../../Shared/FailedPayoutReasonsModal';
import ReversalsInfoModal from '../../Shared/ReversalsInfoModal';
import TransactionBreakdownModal from '../../Shared/TransactionBreakdownModal';
import TransactionStatusModal from '../../Shared/TransactionStatus';
import TransactionDetails from '../../TransactionDetailsNew';
import { ReversePayoutModal } from '../components/PayoutReversals';
import {
  generateMoreDetailFrom,
  generateReceipientInfoFrom,
  generateRemittanceInfoFrom,
  generateSummaryFrom,
  getPayoutActionButtons,
  summaryGenerators
} from './payoutDetailsHelpers';

const api = new APIRequest();
const pageSource = {
  webhookPage: 'web-hook'
};

const PayOutDetails = () => {
  const { id } = useParams<Record<string, string>>();
  const { feedbackInit } = useFeedbackHandler();

  const userAccess = useSetUserAccess() as { [key: string]: boolean };
  const hasAccess = isAllowed(userAccess, ['payout_details.view']);
  const hasPayoutReversalAccess = isAllowed(userAccess, ['payout_reversals.create']);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const origin = queryParams.get('origin');

  const [transactionBreakdownModalOpen, setTransactionBreakdownModalOpen] = useState(false);
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);

  useEffect(() => {
    if (userAccess && !hasAccess) {
      history.push('/dashboard/access-denied', null);
    }
  }, [userAccess]);

  const selectAPI = () => {
    if (origin && origin === pageSource.webhookPage) {
      return api.getSingleTransactionFromWebhookServices('payouts', id, 'reference');
    }
    return api.getSingleTransaction('payouts', id);
  };

  const {
    data,
    isLoading,
    refetch: refetchTransaction
  } = useQuery(`PAYOUT_DETAILS_${id}`, selectAPI, {
    onError: e => {
      logError(e);
      feedbackInit({
        message: `There has been an error fetching the details for the payout: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    },
    enabled: !!hasAccess
  });

  const {
    amount,
    amount_charged: amountCharged,
    payout_reversal: payoutReversal,
    amount_paid: netAmount,
    currency,
    fee,
    vat,
    payment,
    meta
  } = (data || {}) as TTransactionDetails;

  const [isReasonForFailureModalOpen, setIsReasonForFailureModalOpen] = useState(false);
  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();
  const [showReversalInitiateModal, setShowReversalInitiateModal] = useState(false);

  const triggerReasonForFailureModal = () => {
    setIsReasonForFailureModalOpen(true);
  };

  const triggerReversalInitiateModal = () => {
    setShowReversalInitiateModal(true);
  };

  const summaries = generateSummaryFrom(data as IPayOutDetails['data']);
  const actionButtons = getPayoutActionButtons({ data, triggerReversalInitiateModal });
  const moreDetails = generateMoreDetailFrom({ data, triggerReasonForFailureModal, state, updateTransactionStatusModalState, userAccess });
  const receipientInfo = generateReceipientInfoFrom(data as IPayOutDetails['data']);
  const remittanaceInfo = generateRemittanceInfoFrom(data as IPayOutDetails['data']);

  const disputes = {
    refunds: [],
    chargebacks: [],
    reversals: payoutReversal?.id ? [payoutReversal] : []
  };

  return (
    <section style={{ padding: '40px' }}>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount as number)}
          currency={data?.currency}
          statusLabels={[
            {
              status: switchTrxnMessage[data?.status as IPayOutDetails['status']]?.name,
              statusBg: switchTrxnMessage[data?.status as IPayOutDetails['status']]?.backgroundColor,
              statusColor: switchTrxnMessage[data?.status as IPayOutDetails['status']]?.color
            }
          ]}
          isLoading={isLoading}
          summaries={summaries}
          actionButtons={hasPayoutReversalAccess && data?.status === 'success' ? actionButtons : []}
        />
        <TransactionDetails.Section
          isLoading={isLoading}
          heading="More Transaction Details"
          summaries={moreDetails}
          showViewBreakdownBtn
          triggerModal={() => setTransactionBreakdownModalOpen(true)}
        />
        {data?.remittance_data && (
          <TransactionDetails.Section isLoading={isLoading} heading="Remittance Information" summaries={remittanaceInfo} />
        )}
        <TransactionDetails.Section isLoading={isLoading} heading="Recipient's Information" summaries={receipientInfo} />
        <TransactionDetails.Section
          isLoading={isLoading}
          heading="Refunds, Reversals & Chargebacks"
          showLearnMoreBtn={true}
          openLearnMoreModal={() => setIsLearnMoreModalOpen(true)}
        >
          <TransactionDetails.Disputes
            tabs={['refunds', 'reversals', 'chargebacks']}
            disputesGenerators={isObjectNotEmpty(payoutReversal) ? disputes : null}
            summaryGenerators={summaryGenerators}
            childrenGenerators={{}}
            isPayOut
          />
        </TransactionDetails.Section>
      </TransactionDetails>
      {showReversalInitiateModal && (
        <ReversePayoutModal transaction={data} onReversalInitiated={refetchTransaction} close={() => setShowReversalInitiateModal(false)} />
      )}
      {isLearnMoreModalOpen && <ReversalsInfoModal close={() => setIsLearnMoreModalOpen(false)} visible={isLearnMoreModalOpen} />}
      {isReasonForFailureModalOpen && (
        <FailedPayoutReasons
          close={() => setIsReasonForFailureModalOpen(false)}
          visible={isReasonForFailureModalOpen}
          transactions={data}
        />
      )}
      {state.openTransactionStatusModal && (
        <TransactionStatusModal
          activeTransaction={state.activeTransaction}
          updateModalState={updateTransactionStatusModalState}
          triggerProcessingLoader={handleProcessingLoader}
          transactionType={'payout'}
        />
      )}
      {transactionBreakdownModalOpen && (
        <TransactionBreakdownModal
          transactionData={{
            currency,
            amount_charged: formatAmount(amountCharged),
            amount_paid: formatAmount(amount),
            fees: formatAmount(+fee + (+vat || 0)),
            ...(meta?.additional_fees?.stamp_duty && { stamp_duty_fee: meta?.additional_fees?.stamp_duty }),
            ...(payment?.sentinal_transaction
              ? {
                  tax: formatAmount(Number(payment.sentinal_transaction.vat) + Number(payment.sentinal_transaction.processing_fee)),
                  net_amount: formatAmount(
                    +netAmount - Number(payment.sentinal_transaction.vat) - Number(payment.sentinal_transaction.processing_fee)
                  )
                }
              : { net_amount: netAmount })
          }}
          close={() => setTransactionBreakdownModalOpen(false)}
        />
      )}
    </section>
  );
};

export default PayOutDetails;
