import React from 'react';

const ConfigurationFee = ({
  children,
  title,
  type,
  configType = 'default'
}: {
  children?: React.ReactNode;
  title: string;
  type: string;
  configType?: 'default' | 'reset';
}) => {
  const haveContent = React.Children.count(children);
  if (configType === 'reset' && !haveContent) {
    return null;
  }
  return (
    <div className={`icf ${!haveContent ? 'no-content' : ''}`}>
      {haveContent > 0 ? (
        <>
          <div className="icf__header">
            <div>{title}</div>
            <div>Current Fee</div>
            <div>{configType === 'reset' ? 'Default Fee' : 'New Fee'}</div>
          </div>
          <div className="icf__content">{children}</div>
        </>
      ) : (
        <div>{`${type?.toUpperCase()} verification is currently unavailable for this country.`}</div>
      )}
    </div>
  );
};

export default ConfigurationFee;
