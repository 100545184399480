import React from 'react';
import { Link } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import { LienDataType, LienStatusType, SummaryItemType } from '+types';
import { capitalize, formatAmount, getDate, getTime, truncateString } from '+utils';

export const statusPropsMap = {
  released: { name: 'Lien released', color: '#24B314' },
  active: { name: 'Lien placed', color: '#F32345' },
  withheld: { name: 'Lien debited', color: '#6474FF' }
} satisfies Record<LienStatusType, { name: string; color: string }>;

export function generateSummaryFrom(data: LienDataType): Array<SummaryItemType> {
  return [
    { label: 'Lien Amount', value: `${formatAmount(data?.amount)} ${data?.currency}` },
    { label: 'Lien ID', value: `${data?.reference?.toUpperCase()}` },
    { label: 'Date / Time', value: `${getDate(data?.created_at)}, ${getTime(data?.created_at)}` },
    {
      label: 'Merchant Name',
      value: `${data?.merchant_name}`
    },
    {
      label: 'Transaction ID',
      value: (
        <>
          <div>
            <Copyable text={data?.source_reference ?? 'Not Available'} textModifier={text => truncateString(text, 18)} />
          </div>
          {data?.source_reference && (
            <Link to={`/dashboard/pay-ins/${data?.source_reference}`} className="btn" data-redirectto="original-transaction">
              See Original Transaction
              <i className="os-icon os-icon-arrow-up-right icon-after ml-2" />
            </Link>
          )}
        </>
      )
    }
  ];
}

export function generateLienDetails(data: LienDataType): Array<SummaryItemType> {
  return [
    {
      label: 'Status',
      value: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <div style={{ background: statusPropsMap[data?.status]?.color, borderRadius: '50%', width: '10px', height: '10px' }} />
          <span style={{ color: statusPropsMap[data?.status]?.color ?? '' }}>{`${
            statusPropsMap[data?.status]?.name ?? 'Not Available'
          }`}</span>
        </div>
      )
    },
    { label: 'Lien Amount', value: `${formatAmount(data?.amount)} ${data?.currency}` },
    { label: 'Currency', value: data?.currency },
    { label: 'Date Created', value: `${getDate(data?.created_at)}, ${getTime(data?.created_at)}` },
    {
      label: 'Date Completed',
      value: data?.date_completed ? `${getDate(data?.date_completed)}, ${getTime(data?.date_completed)}` : 'Not Available'
    },
    {
      label: 'Reason',
      value: `${capitalize(data?.reason) ?? 'Not Available'}`
    }
  ];
}
