/* eslint-disable no-use-before-define */
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { ChargebackStatusType, CurrencyType, ICardTransactionDetails, RefundType } from '+types';
import { formatAmount, history, isObjectNotEmpty, switchTrxnMessage } from '+utils';

import { chargebackStatus } from '../Chargebacks/constants';
import {
  childrenGenerators,
  generateBusinessInfoFrom,
  generateCardholderInfoFrom,
  generateMoreDetailFrom,
  generateSummaryFrom,
  RefundChildren,
  summaryGenerators
} from './cardTransactionDetailsData';

const api = new APIRequest();

export default function CardTransactionDetails() {
  const { id } = useParams<Record<string, string>>();
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();

  const { data, refetch, isLoading } = useQuery<ICardTransactionDetails>(
    [`TRANSACTION_DETAILS_${id}`],
    () => api.fetchACardTransaction(id),
    {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
          type: 'danger',
          action: { name: 'retry', action: () => refetch() }
        });
        history.goBack();
      }
    }
  );

  const summaries = generateSummaryFrom(data as ICardTransactionDetails);
  const cardholderInfo = generateCardholderInfoFrom(data?.card as ICardTransactionDetails['card']);
  const moreDetails = generateMoreDetailFrom(data as ICardTransactionDetails);
  const businessInfo = generateBusinessInfoFrom();
  const disputes = {
    refunds: data?.refunds && data?.refunds.length > 0 ? data?.refunds : [],
    chargebacks: data?.chargeback ? [data?.chargeback] : []
  };

  childrenGenerators.refunds = (item: RefundType) => (
    <RefundChildren
      refetch={() => queryClient.invalidateQueries([`TRANSACTION_DETAILS_${id}`])}
      refund={item}
      currency={data?.currency as CurrencyType}
      acceptedAmount={(data?.chargeback.pre_arbitration?.accepted_amount as number) ?? (data?.chargeback.accepted_amount as string)}
      chargebackAmount={data?.chargeback.amount as string}
    />
  );

  return (
    <TransactionDetails>
      <TransactionDetails.Header
        heading={formatAmount(data?.amount as number)}
        currency={data?.currency}
        statusLabels={[
          {
            status: data?.status === 'declined' ? 'Declined' : switchTrxnMessage[data?.status as ICardTransactionDetails['status']]?.name,
            statusBg: switchTrxnMessage[data?.status as ICardTransactionDetails['status']]?.backgroundColor,
            statusColor: switchTrxnMessage[data?.status as ICardTransactionDetails['status']]?.color
          }
        ]}
        isLoading={isLoading}
        summaries={summaries}
      />

      <TransactionDetails.Section isLoading={isLoading} heading="Cardholder's Information" summaries={cardholderInfo} />

      <TransactionDetails.Section isLoading={isLoading} heading="More Transaction Details" summaries={moreDetails} />

      <TransactionDetails.Section isLoading={isLoading} heading="Business Information" summaries={businessInfo} />

      <TransactionDetails.Section isLoading={isLoading} heading="Refunds, Reversals & Chargebacks">
        <TransactionDetails.Disputes
          tabs={['refunds', 'chargebacks']}
          disputesGenerators={hasDisputes(data?.refunds, data?.chargeback) ? disputes : null}
          summaryGenerators={summaryGenerators}
          childrenGenerators={canRefundChargeback(data?.chargeback?.status as ChargebackStatusType) ? childrenGenerators : {}}
        />
      </TransactionDetails.Section>
    </TransactionDetails>
  );
}

const hasDisputes = (refunds: undefined | Array<unknown>, chargeback: undefined | unknown) => {
  const refundsExist = refunds && refunds.length > 0;
  const chargebackExists = isObjectNotEmpty(chargeback as object);
  return refundsExist || chargebackExists;
};

function canRefundChargeback(status: ChargebackStatusType) {
  return [
    chargebackStatus.ACCEPTED,
    chargebackStatus.PART_ACCEPTED,
    chargebackStatus.ACCEPTED_PRE_ARB,
    chargebackStatus.PART_ACCEPTED_PRE_ARB,
    chargebackStatus.ACCEPTED_ARB
  ].includes(status);
}
