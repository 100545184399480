import React from 'react';

import { useSetUserAccess } from '+hooks';
import { chargebackActivityUpdateT } from '+types';
import { capitalize, chargebackActivityStatus, downloadFileViaUrl, getDate, getTime, isAllowed } from '+utils';

import downloadSmall from '+assets/img/dashboard/download-small.svg';
import smallFile from '+assets/img/dashboard/file-small.svg';

const ChargebackActivityUpdate = ({ resolveAction, data }: chargebackActivityUpdateT) => {
  const userAccess = useSetUserAccess();
  const details = data && data.status_history && data.status_history.find(status => status.status === data.status);

  const handleDownload = (url: URL) => downloadFileViaUrl(url, 'evidence', 'application/pdf');

  return (
    <div className="chargeback_activity">
      {data && data.status_history && data.status_history.length > 0 ? (
        <div className="chargeback_activity-wrapper">
          {!['accepted', 'lost'].includes(data.status) && (
            <div className="chargeback_activity-item">
              <div className="chargeback_details">
                <div className="chargeback_merchant-wrapper">
                  <span />
                  <p>{`${capitalize(data.merchant)} · ${capitalize(data.account.name)} defended this chargeback`}</p>
                </div>
                <p className="chargeback_activity-info">{capitalize(details?.reason || '')}</p>
                <div className={`chargeback_activity-download ${['won', 'accepted'].includes(data.status) && '--disabled'}`}>
                  <span>
                    <img src={smallFile} alt="small-file" />
                    <p>Evidence.pdf</p>
                  </span>
                  {['partial', 'declined'].includes(data.status) && details && details.evidence_link && (
                    <span
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => handleDownload(details.evidence_link as URL)}
                      onClick={() => handleDownload(details.evidence_link as URL)}
                    >
                      <img src={downloadSmall} alt="download-file" />
                      <p>Download</p>
                    </span>
                  )}
                </div>
                {details && details.date && (
                  <p className="chargeback_activity-time">{`${getDate(details.date)} ${getTime(details.date)}`}</p>
                )}
              </div>
              {['declined', 'partial'].includes(data.status) && isAllowed(userAccess, ['chargebacks.update']) && (
                <button type="button" className="btn btn-primary" onClick={resolveAction}>
                  Manage Dispute
                </button>
              )}
            </div>
          )}
        </div>
      ) : (
        <span className="chargeback_activity-status">-- No activity updates at this time --</span>
      )}
      {data && data.status && (
        <span className="chargeback_activity-status">{chargebackActivityStatus[data.status as keyof typeof chargebackActivityStatus]}</span>
      )}
    </div>
  );
};

export default ChargebackActivityUpdate;
