import { switchTrxnMessage } from '+utils';

import { CurrencyType } from './utils';

export type PayOutType = {
  id: number;
  reference: string;
  status: 'success' | 'failed' | 'pending' | 'requires_auth' | 'reversed' | 'abandoned' | 'processing';
  currency: CurrencyType;
  amount: string;
  fee: string;
  vat: string;
  amount_charged: string;
  amount_paid: string;
  channel: 'api' | 'web' | 'modal';
  created_at: string;
  narration: string;
  message: string;
  trace_id: string;
  batch_reference: string;
  payment_destination_type: 'bank_account' | 'disbursement_wallet';
  transaction_date: string;
  completed_at: string;
  processor: string;
  processor_reference: string;
  unique_reference: string;
  merchant: string;
  account: {
    name: string;
  };
};

export interface RemittanceInformationType {
  remittance_data: {
    type: string;
    sender_name: string;
    source_of_funds: string;
    sender_phone_number: number;
    sender_nationality?: string;
    sender_country_iso?: string;
    sender_service_provider_name?: string;
    destination_phone_number?: number;
    remittance_purpose?: string;
    sender_occupation?: string;
    sender_dob?: string;
    sender_id_type?: string;
    sender_id_number?: number;
  };
}

export interface IPayOutDetails extends RemittanceInformationType {
  status: keyof typeof switchTrxnMessage;
  message: string;
  data: {
    amount: string;
    fee: string;
    vat: string;
    amount_charged: string;
    payout_reversal: {
      id: number;
    };
    currency: string;
    amount_paid: string;
    status: string;
    reference: string;
    narration: string;
    channel: string;
    created_at: string;
    trace_id: string;
    message: string;
    payment_destination_type: string;
    batch_reference: string;
    account_id: number;
    unique_reference: string;
    merchant_bears_cost: boolean;
    transaction_date: string;
    completed_at: string;
    processor: string;
    processor_reference: string;
    processor_messages: string | null;
    payment_reversals_type: number;
    payment: {
      description: string;
      reference: string;
      customer: {
        id: number;
        name: string;
        email: string;
      };
    };
    account: {
      name: string;
    };
    can_request_webhook: boolean;
    destination: {
      type: string;
      details: {
        bank_name: string;
        account_number: string;
        account_name: string;
        mobile_name: string;
        mobile_number: string;
      };
    };
    merchant_id: number;
    meta: {
      receipt: string;
      stan: string;
      authorization_code: string;
    } | null;
    auth_data: {
      pre_auth_expiry: string;
    };
    remittance_data: RemittanceInformationType['remittance_data'];
  };
}

export const payOutsTabs = {
  transactions: 'transactions',
  metrics: 'metrics'
} as const;
