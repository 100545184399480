/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import useSetUserAccess from '+hooks/useSetUserAccess';
import { Storage } from '+services/storage-services';
import { ExplicitPermissionType, ExportActionType, IPermission, IUserDetails } from '+types';
import { capitalize, isAllowed } from '+utils';

import ExportPermissions from '../../components/ExportPermissions';
import ManagePermissionModal from '../../components/ManagePermissionModal';
import UsersPermissionsTable from '../../components/UsersPermissionsTable';
import { filterPermissionOption } from '../../data';

import infoMark from '+assets/img/dashboard/info-i.svg';

interface IUserPermissionsProps {
  userDetails: IUserDetails;
  readOnly?: boolean;
  from: string;
  usersPermissions: IPermission[];
  exportAction: (
    format: ExportActionType['format'],
    close: ExportActionType['close'],
    fieldsToExport: ExportActionType['fieldsToExport']
  ) => void;
}
type ModalType = 'changeRole' | 'deleteExplicitPermission' | 'confirmPermission' | null;
const UserPermissions = ({ userDetails, readOnly, from, usersPermissions, exportAction }: IUserPermissionsProps) => {
  const userAccess = useSetUserAccess();
  const userToken = Storage.checkAuthentication();
  const [modalType, setModalType] = useState<ModalType>(null);
  const [updatedPermissions, setUpdatedPermissions] = useState<string[]>();
  const [reset, onReset] = useState(false);
  const [userExplicitPermissions, setUserExplicitPermissions] = useState<ExplicitPermissionType[]>([]);
  const isCurrentUser = userDetails?.id === +(JSON.parse(atob(userToken?.split('.')[1]))?.sub ?? 0);
  useEffect(() => {
    if (!userDetails) return;
    const userExplicitPermission = userDetails.adminPermissions?.map(permission => ({
      entity: permission.permission.entity,
      slug: permission.permission.slug,
      id: permission.permission.id,
      allowed: permission.allowed
    }));
    setUserExplicitPermissions(userExplicitPermission);
  }, [userDetails]);

  const handleChangeRole = () => setModalType('changeRole');
  const handleCloseModal = () => setModalType(null);
  const handleDeleteExplicitPermission = () => setModalType('deleteExplicitPermission');

  const onResetPermissions = () => {
    setUpdatedPermissions([]);
    onReset(!reset);
  };
  const activeExplicitPermission = userExplicitPermissions?.filter(permission => permission.allowed);
  const renderTableTop = () => {
    if (!userDetails?.adminRoles || userDetails?.adminInvitation?.user_status === 'suspended' || isCurrentUser) return null;
    if (!updatedPermissions || updatedPermissions.length === 0) {
      return (
        <>
          <button type="button" className="btn userdetails-btn --role">
            <span>{capitalize(userDetails?.adminRoles[0]?.name ?? userDetails['user_role.name'])}</span>
          </button>
          {activeExplicitPermission.length > 0 && (
            <>
              <span className="divider-sm" />
              <button className="btn  userdetails-btn --delete" type="button" onClick={handleDeleteExplicitPermission}>
                <span>Delete all explicit permissions</span>
              </button>
            </>
          )}
          <>
            <span className="divider-sm" />
            <button className="btn  userdetails-btn --edit" type="button" onClick={handleChangeRole}>
              <span>Change Role</span>
            </button>
          </>
          <ExportPermissions
            options={filterPermissionOption}
            exportAction={exportAction}
            canExport={isAllowed(userAccess, ['admin_user_permissions.export']) as boolean}
          />
        </>
      );
    }
    return (
      <div className="info-wrapper">
        <div className="info-text-wrapper">
          <div className="info-icon">
            <img src={infoMark} alt="close" aria-hidden />
          </div>
          <p className="info-text">Modifications need to be confirmed in order to take effect</p>
        </div>
        <div className="btn-wrapper">
          <button
            className="btn  userdetails-btn --confirm"
            type="button"
            onClick={() => setModalType('confirmPermission')}
            disabled={!updatedPermissions}
          >
            Confirm
          </button>
          <button className="btn  userdetails-btn --reset" type="button" onClick={onResetPermissions}>
            Reset
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="userdetails-container">
        <div className="userdetails-title-container">
          <p className="userdetails-title">User Permissions</p>
          <div className="userdetails-action">{isAllowed(userAccess, ['admin_users.update']) && renderTableTop()}</div>
        </div>
        <UsersPermissionsTable
          userDetails={userDetails}
          getSelectedPermission={(value: (string | number)[]) => setUpdatedPermissions(value as string[])}
          reset={reset}
          usePermissionId
          readOnly={readOnly || isCurrentUser}
          usersPermissions={usersPermissions}
          userExplicitPermissions={userExplicitPermissions}
        />
      </div>
      {modalType && (
        <ManagePermissionModal
          close={handleCloseModal}
          modalType={modalType}
          userDetails={usersPermissions?.length ? { id: userDetails.id, adminPermissions: usersPermissions } : userDetails}
          updatedPermissions={updatedPermissions}
          action={onResetPermissions}
          from={from}
          currentUsersExplicitPermissions={userExplicitPermissions}
        />
      )}
    </>
  );
};

export default UserPermissions;
